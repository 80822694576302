@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap');

.chrome-picker {
    width: 80% !important;
}

.pointer{
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.ql-editor {
    min-height: 100px !important;
}

.saving {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: .5s;
}

.saving-sm {
    border: 1px solid lightgray;
    left: 50%;
    transform: translateX(-50%);
}

.saving-show {
    opacity: 1 !important;
}

.bg video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.bg .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: black;
    color: #0986FF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-family: Cinzel;
    letter-spacing: 20px;
    text-transform: uppercase;
}

.bg .loading div {
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, #000, #0986FF, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
    0% {
        background-position: -500%;
    }

    100% {
        background-position: 500%;
    }
}

h1,h2,h3,h4,h5,h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.main-content-sm {
    z-index: 1;
    width: 100%;
}

.content,
.content-sm {
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    background-color: rgba(255, 255, 255, 0.5);
    width: 50%;
    height: 80vh;
    font-size: 13px;
    z-index: 1;
}

label {
    font-weight: 800;
}

.content-sm {
    display: grid;
    grid-template-columns: 100fr;
}

.content-sm,
.header-sm {
    width: 90%;
    margin: auto;
    border-radius: 10px;
    border: 3px solid #0986FF;
}

.header-sm {
    border-radius: 10px;
    padding-top: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    background-color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    z-index: 1;
    min-height: 10vh;
    margin-bottom: 40px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-sm h5 {
    font-size: 25px;
}

.header-sm img {
    width: 35% !important;
    margin-bottom: 10px;
}

.grid {
    display: grid;
    grid-template-columns: 70fr 30fr;
    grid-gap: 20px;
}

.fields {
    display: grid;
    grid-template-columns: 50fr 50fr;
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    margin-bottom: 15px;
}

.fields > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.fields div.preview {
    position: relative;
    transition: .5s;
}

.fields div.preview .preview_img {
    position: absolute;
    bottom: 0;
    left: 100%;
    z-index: -1;
    background-size: 100% 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    width: 200px;
    height: 120px;
    opacity: 0;
}

.fields div.preview:hover .preview_img,
.fields div.preview:focus .preview_img {
    opacity: 1;
    z-index: 1;
}

/* .fields div.preview:hover .preview_img:hover {
    background-size: 150% 150% !important;
} */

.fields > div.color-div {
    justify-content: flex-start;
}

.fields input.radio,
.fields input.checkbox {
    width: 20px;
    height: 20px;
}

.grid-left {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
}

.grid-right {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.steps .step {
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    border: 2px solid lightgray;
    color: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.steps .step.active {
    border: 2px solid #0986FF;
    color: #0986FF;
}

.react-responsive-modal-modal {
    width: max-content;
    max-width: 50%;
}

@media only screen and (min-height: 650px) {
    .content {
        height: 80vh;
    }
    
    .content-sm {
        height: 45vh;
    }
}

@media only screen and (min-height: 750px) {
    .content {
        height: 70vh;
    }
    
    .content-sm {
        height: 45vh;
    }
}

@media only screen and (min-height: 900px) {
    .content {
        height: 60vh;
    }
    
    .content-sm {
        height: 45vh;
    }
}

@media only screen and (max-width: 1800px) {
    .content,
    .content-sm,
    .header-sm {
        width: 60%;
    }
}

@media only screen and (max-width: 1500px) {
    .content,
    .content-sm,
    .header-sm {
        width: 70%;
    }
}

@media only screen and (max-width: 1300px) {
    .content,
    .content-sm,
    .header-sm {
        width: 80%;
    }
}

@media only screen and (max-width: 1100px) {
    .content,
    .content-sm,
    .header-sm {
        width: 90%;
    }

    .react-responsive-modal-modal {
        width: 90% !important;
    }
}

@media only screen and (max-width: 768px) {
    .bg {
        align-items: flex-start !important;
        padding-top: 30px;
    }

    .chrome-picker {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    h3 {
        font-size: 20px;
    }
    
    .fields {
        display: block !important;
    }

    .fields > div {
        margin-bottom: 10px;
    }

    .page-title {
        font-size: 20px;
    }

    .content-sm {
        width: 100%;
        height: max-content;
    }

    .header-sm {
        width: 100%;
    }

    .btns-group {
        margin-top: 10px;
    }

    .grid-left {
        padding: 20px;
    }

    .bg .loading {
        font-size: 40px;
    }
}
@font-face {
    font-family: 'ABeeZee';
    src: url('./assets/fonts/ABeeZee.ttf');
}

@font-face {
    font-family: 'Abel';
    src: url('./assets/fonts/Abel.ttf');
}

@font-face {
    font-family: 'Abhaya Libre';
    src: url('./assets/fonts/Abhaya Libre.ttf');
}

@font-face {
    font-family: 'Aboreto';
    src: url('./assets/fonts/Aboreto.ttf');
}

@font-face {
    font-family: 'Abril Fatface';
    src: url('./assets/fonts/Abril Fatface.ttf');
}

@font-face {
    font-family: 'Abyssinica SIL';
    src: url('./assets/fonts/Abyssinica SIL.ttf');
}

@font-face {
    font-family: 'Aclonica';
    src: url('./assets/fonts/Aclonica.ttf');
}

@font-face {
    font-family: 'Acme';
    src: url('./assets/fonts/Acme.ttf');
}

@font-face {
    font-family: 'Actor';
    src: url('./assets/fonts/Actor.ttf');
}

@font-face {
    font-family: 'Adamina';
    src: url('./assets/fonts/Adamina.ttf');
}

@font-face {
    font-family: 'ADLaM Display';
    src: url('./assets/fonts/ADLaM Display.ttf');
}

@font-face {
    font-family: 'Advent Pro';
    src: url('./assets/fonts/Advent Pro.ttf');
}

@font-face {
    font-family: 'Afacad';
    src: url('./assets/fonts/Afacad.ttf');
}

@font-face {
    font-family: 'Agbalumo';
    src: url('./assets/fonts/Agbalumo.ttf');
}

@font-face {
    font-family: 'Agdasima';
    src: url('./assets/fonts/Agdasima.ttf');
}

@font-face {
    font-family: 'Aguafina Script';
    src: url('./assets/fonts/Aguafina Script.ttf');
}

@font-face {
    font-family: 'Akatab';
    src: url('./assets/fonts/Akatab.ttf');
}

@font-face {
    font-family: 'Akaya Kanadaka';
    src: url('./assets/fonts/Akaya Kanadaka.ttf');
}

@font-face {
    font-family: 'Akaya Telivigala';
    src: url('./assets/fonts/Akaya Telivigala.ttf');
}

@font-face {
    font-family: 'Akronim';
    src: url('./assets/fonts/Akronim.ttf');
}

@font-face {
    font-family: 'Akshar';
    src: url('./assets/fonts/Akshar.ttf');
}

@font-face {
    font-family: 'Aladin';
    src: url('./assets/fonts/Aladin.ttf');
}

@font-face {
    font-family: 'Alata';
    src: url('./assets/fonts/Alata.ttf');
}

@font-face {
    font-family: 'Alatsi';
    src: url('./assets/fonts/Alatsi.ttf');
}

@font-face {
    font-family: 'Albert Sans';
    src: url('./assets/fonts/Albert Sans.ttf');
}

@font-face {
    font-family: 'Aldrich';
    src: url('./assets/fonts/Aldrich.ttf');
}

@font-face {
    font-family: 'Alef';
    src: url('./assets/fonts/Alef.ttf');
}

@font-face {
    font-family: 'Alegreya Sans SC';
    src: url('./assets/fonts/Alegreya Sans SC.ttf');
}

@font-face {
    font-family: 'Alegreya Sans';
    src: url('./assets/fonts/Alegreya Sans.ttf');
}

@font-face {
    font-family: 'Alegreya SC';
    src: url('./assets/fonts/Alegreya SC.ttf');
}

@font-face {
    font-family: 'Alegreya';
    src: url('./assets/fonts/Alegreya.ttf');
}

@font-face {
    font-family: 'Aleo';
    src: url('./assets/fonts/Aleo.ttf');
}

@font-face {
    font-family: 'Alex Brush';
    src: url('./assets/fonts/Alex Brush.ttf');
}

@font-face {
    font-family: 'Alexandria';
    src: url('./assets/fonts/Alexandria.ttf');
}

@font-face {
    font-family: 'Alfa Slab One';
    src: url('./assets/fonts/Alfa Slab One.ttf');
}

@font-face {
    font-family: 'Alice';
    src: url('./assets/fonts/Alice.ttf');
}

@font-face {
    font-family: 'Alike Angular';
    src: url('./assets/fonts/Alike Angular.ttf');
}

@font-face {
    font-family: 'Alike';
    src: url('./assets/fonts/Alike.ttf');
}

@font-face {
    font-family: 'Alkalami';
    src: url('./assets/fonts/Alkalami.ttf');
}

@font-face {
    font-family: 'Alkatra';
    src: url('./assets/fonts/Alkatra.ttf');
}

@font-face {
    font-family: 'Allan';
    src: url('./assets/fonts/Allan.ttf');
}

@font-face {
    font-family: 'Allerta Stencil';
    src: url('./assets/fonts/Allerta Stencil.ttf');
}

@font-face {
    font-family: 'Allerta';
    src: url('./assets/fonts/Allerta.ttf');
}

@font-face {
    font-family: 'Allison';
    src: url('./assets/fonts/Allison.ttf');
}

@font-face {
    font-family: 'Allura';
    src: url('./assets/fonts/Allura.ttf');
}

@font-face {
    font-family: 'Almarai';
    src: url('./assets/fonts/Almarai.ttf');
}

@font-face {
    font-family: 'Almendra Display';
    src: url('./assets/fonts/Almendra Display.ttf');
}

@font-face {
    font-family: 'Almendra SC';
    src: url('./assets/fonts/Almendra SC.ttf');
}

@font-face {
    font-family: 'Almendra';
    src: url('./assets/fonts/Almendra.ttf');
}

@font-face {
    font-family: 'Alumni Sans Collegiate One';
    src: url('./assets/fonts/Alumni Sans Collegiate One.ttf');
}

@font-face {
    font-family: 'Alumni Sans Inline One';
    src: url('./assets/fonts/Alumni Sans Inline One.ttf');
}

@font-face {
    font-family: 'Alumni Sans Pinstripe';
    src: url('./assets/fonts/Alumni Sans Pinstripe.ttf');
}

@font-face {
    font-family: 'Alumni Sans';
    src: url('./assets/fonts/Alumni Sans.ttf');
}

@font-face {
    font-family: 'Amarante';
    src: url('./assets/fonts/Amarante.ttf');
}

@font-face {
    font-family: 'Amaranth';
    src: url('./assets/fonts/Amaranth.ttf');
}

@font-face {
    font-family: 'Amatic SC';
    src: url('./assets/fonts/Amatic SC.ttf');
}

@font-face {
    font-family: 'Amethysta';
    src: url('./assets/fonts/Amethysta.ttf');
}

@font-face {
    font-family: 'Amiko';
    src: url('./assets/fonts/Amiko.ttf');
}

@font-face {
    font-family: 'Amiri Quran';
    src: url('./assets/fonts/Amiri Quran.ttf');
}

@font-face {
    font-family: 'Amiri';
    src: url('./assets/fonts/Amiri.ttf');
}

@font-face {
    font-family: 'Amita';
    src: url('./assets/fonts/Amita.ttf');
}

@font-face {
    font-family: 'Anaheim';
    src: url('./assets/fonts/Anaheim.ttf');
}

@font-face {
    font-family: 'Andada Pro';
    src: url('./assets/fonts/Andada Pro.ttf');
}

@font-face {
    font-family: 'Andika';
    src: url('./assets/fonts/Andika.ttf');
}

@font-face {
    font-family: 'Anek Bangla';
    src: url('./assets/fonts/Anek Bangla.ttf');
}

@font-face {
    font-family: 'Anek Devanagari';
    src: url('./assets/fonts/Anek Devanagari.ttf');
}

@font-face {
    font-family: 'Anek Gujarati';
    src: url('./assets/fonts/Anek Gujarati.ttf');
}

@font-face {
    font-family: 'Anek Gurmukhi';
    src: url('./assets/fonts/Anek Gurmukhi.ttf');
}

@font-face {
    font-family: 'Anek Kannada';
    src: url('./assets/fonts/Anek Kannada.ttf');
}

@font-face {
    font-family: 'Anek Latin';
    src: url('./assets/fonts/Anek Latin.ttf');
}

@font-face {
    font-family: 'Anek Malayalam';
    src: url('./assets/fonts/Anek Malayalam.ttf');
}

@font-face {
    font-family: 'Anek Odia';
    src: url('./assets/fonts/Anek Odia.ttf');
}

@font-face {
    font-family: 'Anek Tamil';
    src: url('./assets/fonts/Anek Tamil.ttf');
}

@font-face {
    font-family: 'Anek Telugu';
    src: url('./assets/fonts/Anek Telugu.ttf');
}

@font-face {
    font-family: 'Angkor';
    src: url('./assets/fonts/Angkor.ttf');
}

@font-face {
    font-family: 'Annapurna SIL';
    src: url('./assets/fonts/Annapurna SIL.ttf');
}

@font-face {
    font-family: 'Annie Use Your Telescope';
    src: url('./assets/fonts/Annie Use Your Telescope.ttf');
}

@font-face {
    font-family: 'Anonymous Pro';
    src: url('./assets/fonts/Anonymous Pro.ttf');
}

@font-face {
    font-family: 'Anta';
    src: url('./assets/fonts/Anta.ttf');
}

@font-face {
    font-family: 'Antic Didone';
    src: url('./assets/fonts/Antic Didone.ttf');
}

@font-face {
    font-family: 'Antic Slab';
    src: url('./assets/fonts/Antic Slab.ttf');
}

@font-face {
    font-family: 'Antic';
    src: url('./assets/fonts/Antic.ttf');
}

@font-face {
    font-family: 'Anton';
    src: url('./assets/fonts/Anton.ttf');
}

@font-face {
    font-family: 'Antonio';
    src: url('./assets/fonts/Antonio.ttf');
}

@font-face {
    font-family: 'Anuphan';
    src: url('./assets/fonts/Anuphan.ttf');
}

@font-face {
    font-family: 'Anybody';
    src: url('./assets/fonts/Anybody.ttf');
}

@font-face {
    font-family: 'Aoboshi One';
    src: url('./assets/fonts/Aoboshi One.ttf');
}

@font-face {
    font-family: 'AR One Sans';
    src: url('./assets/fonts/AR One Sans.ttf');
}

@font-face {
    font-family: 'Arapey';
    src: url('./assets/fonts/Arapey.ttf');
}

@font-face {
    font-family: 'Arbutus Slab';
    src: url('./assets/fonts/Arbutus Slab.ttf');
}

@font-face {
    font-family: 'Arbutus';
    src: url('./assets/fonts/Arbutus.ttf');
}

@font-face {
    font-family: 'Architects Daughter';
    src: url('./assets/fonts/Architects Daughter.ttf');
}

@font-face {
    font-family: 'Archivo Black';
    src: url('./assets/fonts/Archivo Black.ttf');
}

@font-face {
    font-family: 'Archivo Narrow';
    src: url('./assets/fonts/Archivo Narrow.ttf');
}

@font-face {
    font-family: 'Archivo';
    src: url('./assets/fonts/Archivo.ttf');
}

@font-face {
    font-family: 'Are You Serious';
    src: url('./assets/fonts/Are You Serious.ttf');
}

@font-face {
    font-family: 'Aref Ruqaa Ink';
    src: url('./assets/fonts/Aref Ruqaa Ink.ttf');
}

@font-face {
    font-family: 'Aref Ruqaa';
    src: url('./assets/fonts/Aref Ruqaa.ttf');
}

@font-face {
    font-family: 'Arima';
    src: url('./assets/fonts/Arima.ttf');
}

@font-face {
    font-family: 'Arimo';
    src: url('./assets/fonts/Arimo.ttf');
}

@font-face {
    font-family: 'Arizonia';
    src: url('./assets/fonts/Arizonia.ttf');
}

@font-face {
    font-family: 'Armata';
    src: url('./assets/fonts/Armata.ttf');
}

@font-face {
    font-family: 'Arsenal';
    src: url('./assets/fonts/Arsenal.ttf');
}

@font-face {
    font-family: 'Artifika';
    src: url('./assets/fonts/Artifika.ttf');
}

@font-face {
    font-family: 'Arvo';
    src: url('./assets/fonts/Arvo.ttf');
}

@font-face {
    font-family: 'Arya';
    src: url('./assets/fonts/Arya.ttf');
}

@font-face {
    font-family: 'Asap Condensed';
    src: url('./assets/fonts/Asap Condensed.ttf');
}

@font-face {
    font-family: 'Asap';
    src: url('./assets/fonts/Asap.ttf');
}

@font-face {
    font-family: 'Asar';
    src: url('./assets/fonts/Asar.ttf');
}

@font-face {
    font-family: 'Asset';
    src: url('./assets/fonts/Asset.ttf');
}

@font-face {
    font-family: 'Assistant';
    src: url('./assets/fonts/Assistant.ttf');
}

@font-face {
    font-family: 'Astloch';
    src: url('./assets/fonts/Astloch.ttf');
}

@font-face {
    font-family: 'Asul';
    src: url('./assets/fonts/Asul.ttf');
}

@font-face {
    font-family: 'Athiti';
    src: url('./assets/fonts/Athiti.ttf');
}

@font-face {
    font-family: 'Atkinson Hyperlegible';
    src: url('./assets/fonts/Atkinson Hyperlegible.ttf');
}

@font-face {
    font-family: 'Atma';
    src: url('./assets/fonts/Atma.ttf');
}

@font-face {
    font-family: 'Atomic Age';
    src: url('./assets/fonts/Atomic Age.ttf');
}

@font-face {
    font-family: 'Aubrey';
    src: url('./assets/fonts/Aubrey.ttf');
}

@font-face {
    font-family: 'Audiowide';
    src: url('./assets/fonts/Audiowide.ttf');
}

@font-face {
    font-family: 'Autour One';
    src: url('./assets/fonts/Autour One.ttf');
}

@font-face {
    font-family: 'Average Sans';
    src: url('./assets/fonts/Average Sans.ttf');
}

@font-face {
    font-family: 'Average';
    src: url('./assets/fonts/Average.ttf');
}

@font-face {
    font-family: 'Averia Gruesa Libre';
    src: url('./assets/fonts/Averia Gruesa Libre.ttf');
}

@font-face {
    font-family: 'Averia Libre';
    src: url('./assets/fonts/Averia Libre.ttf');
}

@font-face {
    font-family: 'Averia Sans Libre';
    src: url('./assets/fonts/Averia Sans Libre.ttf');
}

@font-face {
    font-family: 'Averia Serif Libre';
    src: url('./assets/fonts/Averia Serif Libre.ttf');
}

@font-face {
    font-family: 'Azeret Mono';
    src: url('./assets/fonts/Azeret Mono.ttf');
}

@font-face {
    font-family: 'B612 Mono';
    src: url('./assets/fonts/B612 Mono.ttf');
}

@font-face {
    font-family: 'B612';
    src: url('./assets/fonts/B612.ttf');
}

@font-face {
    font-family: 'Babylonica';
    src: url('./assets/fonts/Babylonica.ttf');
}

@font-face {
    font-family: 'Bacasime Antique';
    src: url('./assets/fonts/Bacasime Antique.ttf');
}

@font-face {
    font-family: 'Bad Script';
    src: url('./assets/fonts/Bad Script.ttf');
}

@font-face {
    font-family: 'Bagel Fat One';
    src: url('./assets/fonts/Bagel Fat One.ttf');
}

@font-face {
    font-family: 'Bahiana';
    src: url('./assets/fonts/Bahiana.ttf');
}

@font-face {
    font-family: 'Bahianita';
    src: url('./assets/fonts/Bahianita.ttf');
}

@font-face {
    font-family: 'Bai Jamjuree';
    src: url('./assets/fonts/Bai Jamjuree.ttf');
}

@font-face {
    font-family: 'Bakbak One';
    src: url('./assets/fonts/Bakbak One.ttf');
}

@font-face {
    font-family: 'Ballet';
    src: url('./assets/fonts/Ballet.ttf');
}

@font-face {
    font-family: 'Baloo 2';
    src: url('./assets/fonts/Baloo 2.ttf');
}

@font-face {
    font-family: 'Baloo Bhai 2';
    src: url('./assets/fonts/Baloo Bhai 2.ttf');
}

@font-face {
    font-family: 'Baloo Bhaijaan 2';
    src: url('./assets/fonts/Baloo Bhaijaan 2.ttf');
}

@font-face {
    font-family: 'Baloo Bhaina 2';
    src: url('./assets/fonts/Baloo Bhaina 2.ttf');
}

@font-face {
    font-family: 'Baloo Chettan 2';
    src: url('./assets/fonts/Baloo Chettan 2.ttf');
}

@font-face {
    font-family: 'Baloo Da 2';
    src: url('./assets/fonts/Baloo Da 2.ttf');
}

@font-face {
    font-family: 'Baloo Paaji 2';
    src: url('./assets/fonts/Baloo Paaji 2.ttf');
}

@font-face {
    font-family: 'Baloo Tamma 2';
    src: url('./assets/fonts/Baloo Tamma 2.ttf');
}

@font-face {
    font-family: 'Baloo Tammudu 2';
    src: url('./assets/fonts/Baloo Tammudu 2.ttf');
}

@font-face {
    font-family: 'Baloo Thambi 2';
    src: url('./assets/fonts/Baloo Thambi 2.ttf');
}

@font-face {
    font-family: 'Balsamiq Sans';
    src: url('./assets/fonts/Balsamiq Sans.ttf');
}

@font-face {
    font-family: 'Balthazar';
    src: url('./assets/fonts/Balthazar.ttf');
}

@font-face {
    font-family: 'Bangers';
    src: url('./assets/fonts/Bangers.ttf');
}

@font-face {
    font-family: 'Barlow Condensed';
    src: url('./assets/fonts/Barlow Condensed.ttf');
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('./assets/fonts/Barlow Semi Condensed.ttf');
}

@font-face {
    font-family: 'Barlow';
    src: url('./assets/fonts/Barlow.ttf');
}

@font-face {
    font-family: 'Barriecito';
    src: url('./assets/fonts/Barriecito.ttf');
}

@font-face {
    font-family: 'Barrio';
    src: url('./assets/fonts/Barrio.ttf');
}

@font-face {
    font-family: 'Basic';
    src: url('./assets/fonts/Basic.ttf');
}

@font-face {
    font-family: 'Baskervville';
    src: url('./assets/fonts/Baskervville.ttf');
}

@font-face {
    font-family: 'Battambang';
    src: url('./assets/fonts/Battambang.ttf');
}

@font-face {
    font-family: 'Baumans';
    src: url('./assets/fonts/Baumans.ttf');
}

@font-face {
    font-family: 'Bayon';
    src: url('./assets/fonts/Bayon.ttf');
}

@font-face {
    font-family: 'Be Vietnam Pro';
    src: url('./assets/fonts/Be Vietnam Pro.ttf');
}

@font-face {
    font-family: 'Beau Rivage';
    src: url('./assets/fonts/Beau Rivage.ttf');
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/Bebas Neue.ttf');
}

@font-face {
    font-family: 'Belanosima';
    src: url('./assets/fonts/Belanosima.ttf');
}

@font-face {
    font-family: 'Belgrano';
    src: url('./assets/fonts/Belgrano.ttf');
}

@font-face {
    font-family: 'Bellefair';
    src: url('./assets/fonts/Bellefair.ttf');
}

@font-face {
    font-family: 'Belleza';
    src: url('./assets/fonts/Belleza.ttf');
}

@font-face {
    font-family: 'Bellota Text';
    src: url('./assets/fonts/Bellota Text.ttf');
}

@font-face {
    font-family: 'Bellota';
    src: url('./assets/fonts/Bellota.ttf');
}

@font-face {
    font-family: 'BenchNine';
    src: url('./assets/fonts/BenchNine.ttf');
}

@font-face {
    font-family: 'Benne';
    src: url('./assets/fonts/Benne.ttf');
}

@font-face {
    font-family: 'Bentham';
    src: url('./assets/fonts/Bentham.ttf');
}

@font-face {
    font-family: 'Berkshire Swash';
    src: url('./assets/fonts/Berkshire Swash.ttf');
}

@font-face {
    font-family: 'Besley';
    src: url('./assets/fonts/Besley.ttf');
}

@font-face {
    font-family: 'Beth Ellen';
    src: url('./assets/fonts/Beth Ellen.ttf');
}

@font-face {
    font-family: 'Bevan';
    src: url('./assets/fonts/Bevan.ttf');
}

@font-face {
    font-family: 'BhuTuka Expanded One';
    src: url('./assets/fonts/BhuTuka Expanded One.ttf');
}

@font-face {
    font-family: 'Big Shoulders Display';
    src: url('./assets/fonts/Big Shoulders Display.ttf');
}

@font-face {
    font-family: 'Big Shoulders Inline Display';
    src: url('./assets/fonts/Big Shoulders Inline Display.ttf');
}

@font-face {
    font-family: 'Big Shoulders Inline Text';
    src: url('./assets/fonts/Big Shoulders Inline Text.ttf');
}

@font-face {
    font-family: 'Big Shoulders Stencil Display';
    src: url('./assets/fonts/Big Shoulders Stencil Display.ttf');
}

@font-face {
    font-family: 'Big Shoulders Stencil Text';
    src: url('./assets/fonts/Big Shoulders Stencil Text.ttf');
}

@font-face {
    font-family: 'Big Shoulders Text';
    src: url('./assets/fonts/Big Shoulders Text.ttf');
}

@font-face {
    font-family: 'Bigelow Rules';
    src: url('./assets/fonts/Bigelow Rules.ttf');
}

@font-face {
    font-family: 'Bigshot One';
    src: url('./assets/fonts/Bigshot One.ttf');
}

@font-face {
    font-family: 'Bilbo Swash Caps';
    src: url('./assets/fonts/Bilbo Swash Caps.ttf');
}

@font-face {
    font-family: 'Bilbo';
    src: url('./assets/fonts/Bilbo.ttf');
}

@font-face {
    font-family: 'BioRhyme Expanded';
    src: url('./assets/fonts/BioRhyme Expanded.ttf');
}

@font-face {
    font-family: 'BioRhyme';
    src: url('./assets/fonts/BioRhyme.ttf');
}

@font-face {
    font-family: 'Birthstone Bounce';
    src: url('./assets/fonts/Birthstone Bounce.ttf');
}

@font-face {
    font-family: 'Birthstone';
    src: url('./assets/fonts/Birthstone.ttf');
}

@font-face {
    font-family: 'Biryani';
    src: url('./assets/fonts/Biryani.ttf');
}

@font-face {
    font-family: 'Bitter';
    src: url('./assets/fonts/Bitter.ttf');
}

@font-face {
    font-family: 'BIZ UDGothic';
    src: url('./assets/fonts/BIZ UDGothic.ttf');
}

@font-face {
    font-family: 'BIZ UDMincho';
    src: url('./assets/fonts/BIZ UDMincho.ttf');
}

@font-face {
    font-family: 'BIZ UDPGothic';
    src: url('./assets/fonts/BIZ UDPGothic.ttf');
}

@font-face {
    font-family: 'BIZ UDPMincho';
    src: url('./assets/fonts/BIZ UDPMincho.ttf');
}

@font-face {
    font-family: 'Black And White Picture';
    src: url('./assets/fonts/Black And White Picture.ttf');
}

@font-face {
    font-family: 'Black Han Sans';
    src: url('./assets/fonts/Black Han Sans.ttf');
}

@font-face {
    font-family: 'Black Ops One';
    src: url('./assets/fonts/Black Ops One.ttf');
}

@font-face {
    font-family: 'Blaka Hollow';
    src: url('./assets/fonts/Blaka Hollow.ttf');
}

@font-face {
    font-family: 'Blaka Ink';
    src: url('./assets/fonts/Blaka Ink.ttf');
}

@font-face {
    font-family: 'Blaka';
    src: url('./assets/fonts/Blaka.ttf');
}

@font-face {
    font-family: 'Blinker';
    src: url('./assets/fonts/Blinker.ttf');
}

@font-face {
    font-family: 'Bodoni Moda';
    src: url('./assets/fonts/Bodoni Moda.ttf');
}

@font-face {
    font-family: 'Bokor';
    src: url('./assets/fonts/Bokor.ttf');
}

@font-face {
    font-family: 'Bona Nova';
    src: url('./assets/fonts/Bona Nova.ttf');
}

@font-face {
    font-family: 'Bonbon';
    src: url('./assets/fonts/Bonbon.ttf');
}

@font-face {
    font-family: 'Bonheur Royale';
    src: url('./assets/fonts/Bonheur Royale.ttf');
}

@font-face {
    font-family: 'Boogaloo';
    src: url('./assets/fonts/Boogaloo.ttf');
}

@font-face {
    font-family: 'Borel';
    src: url('./assets/fonts/Borel.ttf');
}

@font-face {
    font-family: 'Bowlby One SC';
    src: url('./assets/fonts/Bowlby One SC.ttf');
}

@font-face {
    font-family: 'Bowlby One';
    src: url('./assets/fonts/Bowlby One.ttf');
}

@font-face {
    font-family: 'Braah One';
    src: url('./assets/fonts/Braah One.ttf');
}

@font-face {
    font-family: 'Brawler';
    src: url('./assets/fonts/Brawler.ttf');
}

@font-face {
    font-family: 'Bree Serif';
    src: url('./assets/fonts/Bree Serif.ttf');
}

@font-face {
    font-family: 'Bricolage Grotesque';
    src: url('./assets/fonts/Bricolage Grotesque.ttf');
}

@font-face {
    font-family: 'Bruno Ace SC';
    src: url('./assets/fonts/Bruno Ace SC.ttf');
}

@font-face {
    font-family: 'Bruno Ace';
    src: url('./assets/fonts/Bruno Ace.ttf');
}

@font-face {
    font-family: 'Brygada 1918';
    src: url('./assets/fonts/Brygada 1918.ttf');
}

@font-face {
    font-family: 'Bubblegum Sans';
    src: url('./assets/fonts/Bubblegum Sans.ttf');
}

@font-face {
    font-family: 'Bubbler One';
    src: url('./assets/fonts/Bubbler One.ttf');
}

@font-face {
    font-family: 'Buda';
    src: url('./assets/fonts/Buda.ttf');
}

@font-face {
    font-family: 'Buenard';
    src: url('./assets/fonts/Buenard.ttf');
}

@font-face {
    font-family: 'Bungee Hairline';
    src: url('./assets/fonts/Bungee Hairline.ttf');
}

@font-face {
    font-family: 'Bungee Inline';
    src: url('./assets/fonts/Bungee Inline.ttf');
}

@font-face {
    font-family: 'Bungee Outline';
    src: url('./assets/fonts/Bungee Outline.ttf');
}

@font-face {
    font-family: 'Bungee Shade';
    src: url('./assets/fonts/Bungee Shade.ttf');
}

@font-face {
    font-family: 'Bungee Spice';
    src: url('./assets/fonts/Bungee Spice.ttf');
}

@font-face {
    font-family: 'Bungee';
    src: url('./assets/fonts/Bungee.ttf');
}

@font-face {
    font-family: 'Butcherman';
    src: url('./assets/fonts/Butcherman.ttf');
}

@font-face {
    font-family: 'Butterfly Kids';
    src: url('./assets/fonts/Butterfly Kids.ttf');
}

@font-face {
    font-family: 'Cabin Condensed';
    src: url('./assets/fonts/Cabin Condensed.ttf');
}

@font-face {
    font-family: 'Cabin Sketch';
    src: url('./assets/fonts/Cabin Sketch.ttf');
}

@font-face {
    font-family: 'Cabin';
    src: url('./assets/fonts/Cabin.ttf');
}

@font-face {
    font-family: 'Caesar Dressing';
    src: url('./assets/fonts/Caesar Dressing.ttf');
}

@font-face {
    font-family: 'Cagliostro';
    src: url('./assets/fonts/Cagliostro.ttf');
}

@font-face {
    font-family: 'Cairo Play';
    src: url('./assets/fonts/Cairo Play.ttf');
}

@font-face {
    font-family: 'Cairo';
    src: url('./assets/fonts/Cairo.ttf');
}

@font-face {
    font-family: 'Caladea';
    src: url('./assets/fonts/Caladea.ttf');
}

@font-face {
    font-family: 'Calistoga';
    src: url('./assets/fonts/Calistoga.ttf');
}

@font-face {
    font-family: 'Calligraffitti';
    src: url('./assets/fonts/Calligraffitti.ttf');
}

@font-face {
    font-family: 'Cambay';
    src: url('./assets/fonts/Cambay.ttf');
}

@font-face {
    font-family: 'Cambo';
    src: url('./assets/fonts/Cambo.ttf');
}

@font-face {
    font-family: 'Candal';
    src: url('./assets/fonts/Candal.ttf');
}

@font-face {
    font-family: 'Cantarell';
    src: url('./assets/fonts/Cantarell.ttf');
}

@font-face {
    font-family: 'Cantata One';
    src: url('./assets/fonts/Cantata One.ttf');
}

@font-face {
    font-family: 'Cantora One';
    src: url('./assets/fonts/Cantora One.ttf');
}

@font-face {
    font-family: 'Caprasimo';
    src: url('./assets/fonts/Caprasimo.ttf');
}

@font-face {
    font-family: 'Capriola';
    src: url('./assets/fonts/Capriola.ttf');
}

@font-face {
    font-family: 'Caramel';
    src: url('./assets/fonts/Caramel.ttf');
}

@font-face {
    font-family: 'Carattere';
    src: url('./assets/fonts/Carattere.ttf');
}

@font-face {
    font-family: 'Cardo';
    src: url('./assets/fonts/Cardo.ttf');
}

@font-face {
    font-family: 'Carlito';
    src: url('./assets/fonts/Carlito.ttf');
}

@font-face {
    font-family: 'Carme';
    src: url('./assets/fonts/Carme.ttf');
}

@font-face {
    font-family: 'Carrois Gothic SC';
    src: url('./assets/fonts/Carrois Gothic SC.ttf');
}

@font-face {
    font-family: 'Carrois Gothic';
    src: url('./assets/fonts/Carrois Gothic.ttf');
}

@font-face {
    font-family: 'Carter One';
    src: url('./assets/fonts/Carter One.ttf');
}

@font-face {
    font-family: 'Castoro Titling';
    src: url('./assets/fonts/Castoro Titling.ttf');
}

@font-face {
    font-family: 'Castoro';
    src: url('./assets/fonts/Castoro.ttf');
}

@font-face {
    font-family: 'Catamaran';
    src: url('./assets/fonts/Catamaran.ttf');
}

@font-face {
    font-family: 'Caudex';
    src: url('./assets/fonts/Caudex.ttf');
}

@font-face {
    font-family: 'Caveat Brush';
    src: url('./assets/fonts/Caveat Brush.ttf');
}

@font-face {
    font-family: 'Caveat';
    src: url('./assets/fonts/Caveat.ttf');
}

@font-face {
    font-family: 'Cedarville Cursive';
    src: url('./assets/fonts/Cedarville Cursive.ttf');
}

@font-face {
    font-family: 'Ceviche One';
    src: url('./assets/fonts/Ceviche One.ttf');
}

@font-face {
    font-family: 'Chakra Petch';
    src: url('./assets/fonts/Chakra Petch.ttf');
}

@font-face {
    font-family: 'Changa One';
    src: url('./assets/fonts/Changa One.ttf');
}

@font-face {
    font-family: 'Changa';
    src: url('./assets/fonts/Changa.ttf');
}

@font-face {
    font-family: 'Chango';
    src: url('./assets/fonts/Chango.ttf');
}

@font-face {
    font-family: 'Charis SIL';
    src: url('./assets/fonts/Charis SIL.ttf');
}

@font-face {
    font-family: 'Charm';
    src: url('./assets/fonts/Charm.ttf');
}

@font-face {
    font-family: 'Charmonman';
    src: url('./assets/fonts/Charmonman.ttf');
}

@font-face {
    font-family: 'Chathura';
    src: url('./assets/fonts/Chathura.ttf');
}

@font-face {
    font-family: 'Chau Philomene One';
    src: url('./assets/fonts/Chau Philomene One.ttf');
}

@font-face {
    font-family: 'Chela One';
    src: url('./assets/fonts/Chela One.ttf');
}

@font-face {
    font-family: 'Chelsea Market';
    src: url('./assets/fonts/Chelsea Market.ttf');
}

@font-face {
    font-family: 'Chenla';
    src: url('./assets/fonts/Chenla.ttf');
}

@font-face {
    font-family: 'Cherish';
    src: url('./assets/fonts/Cherish.ttf');
}

@font-face {
    font-family: 'Cherry Bomb One';
    src: url('./assets/fonts/Cherry Bomb One.ttf');
}

@font-face {
    font-family: 'Cherry Cream Soda';
    src: url('./assets/fonts/Cherry Cream Soda.ttf');
}

@font-face {
    font-family: 'Cherry Swash';
    src: url('./assets/fonts/Cherry Swash.ttf');
}

@font-face {
    font-family: 'Chewy';
    src: url('./assets/fonts/Chewy.ttf');
}

@font-face {
    font-family: 'Chicle';
    src: url('./assets/fonts/Chicle.ttf');
}

@font-face {
    font-family: 'Chilanka';
    src: url('./assets/fonts/Chilanka.ttf');
}

@font-face {
    font-family: 'Chivo Mono';
    src: url('./assets/fonts/Chivo Mono.ttf');
}

@font-face {
    font-family: 'Chivo';
    src: url('./assets/fonts/Chivo.ttf');
}

@font-face {
    font-family: 'Chokokutai';
    src: url('./assets/fonts/Chokokutai.ttf');
}

@font-face {
    font-family: 'Chonburi';
    src: url('./assets/fonts/Chonburi.ttf');
}

@font-face {
    font-family: 'Cinzel Decorative';
    src: url('./assets/fonts/Cinzel Decorative.ttf');
}

@font-face {
    font-family: 'Cinzel';
    src: url('./assets/fonts/Cinzel.ttf');
}

@font-face {
    font-family: 'Clicker Script';
    src: url('./assets/fonts/Clicker Script.ttf');
}

@font-face {
    font-family: 'Climate Crisis';
    src: url('./assets/fonts/Climate Crisis.ttf');
}

@font-face {
    font-family: 'Coda';
    src: url('./assets/fonts/Coda.ttf');
}

@font-face {
    font-family: 'Codystar';
    src: url('./assets/fonts/Codystar.ttf');
}

@font-face {
    font-family: 'Coiny';
    src: url('./assets/fonts/Coiny.ttf');
}

@font-face {
    font-family: 'Combo';
    src: url('./assets/fonts/Combo.ttf');
}

@font-face {
    font-family: 'Comfortaa';
    src: url('./assets/fonts/Comfortaa.ttf');
}

@font-face {
    font-family: 'Comforter Brush';
    src: url('./assets/fonts/Comforter Brush.ttf');
}

@font-face {
    font-family: 'Comforter';
    src: url('./assets/fonts/Comforter.ttf');
}

@font-face {
    font-family: 'Comic Neue';
    src: url('./assets/fonts/Comic Neue.ttf');
}

@font-face {
    font-family: 'Coming Soon';
    src: url('./assets/fonts/Coming Soon.ttf');
}

@font-face {
    font-family: 'Comme';
    src: url('./assets/fonts/Comme.ttf');
}

@font-face {
    font-family: 'Commissioner';
    src: url('./assets/fonts/Commissioner.ttf');
}

@font-face {
    font-family: 'Concert One';
    src: url('./assets/fonts/Concert One.ttf');
}

@font-face {
    font-family: 'Condiment';
    src: url('./assets/fonts/Condiment.ttf');
}

@font-face {
    font-family: 'Content';
    src: url('./assets/fonts/Content.ttf');
}

@font-face {
    font-family: 'Contrail One';
    src: url('./assets/fonts/Contrail One.ttf');
}

@font-face {
    font-family: 'Convergence';
    src: url('./assets/fonts/Convergence.ttf');
}

@font-face {
    font-family: 'Cookie';
    src: url('./assets/fonts/Cookie.ttf');
}

@font-face {
    font-family: 'Copse';
    src: url('./assets/fonts/Copse.ttf');
}

@font-face {
    font-family: 'Corben';
    src: url('./assets/fonts/Corben.ttf');
}

@font-face {
    font-family: 'Corinthia';
    src: url('./assets/fonts/Corinthia.ttf');
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./assets/fonts/Cormorant Garamond.ttf');
}

@font-face {
    font-family: 'Cormorant Infant';
    src: url('./assets/fonts/Cormorant Infant.ttf');
}

@font-face {
    font-family: 'Cormorant SC';
    src: url('./assets/fonts/Cormorant SC.ttf');
}

@font-face {
    font-family: 'Cormorant Unicase';
    src: url('./assets/fonts/Cormorant Unicase.ttf');
}

@font-face {
    font-family: 'Cormorant Upright';
    src: url('./assets/fonts/Cormorant Upright.ttf');
}

@font-face {
    font-family: 'Cormorant';
    src: url('./assets/fonts/Cormorant.ttf');
}

@font-face {
    font-family: 'Courgette';
    src: url('./assets/fonts/Courgette.ttf');
}

@font-face {
    font-family: 'Courier Prime';
    src: url('./assets/fonts/Courier Prime.ttf');
}

@font-face {
    font-family: 'Cousine';
    src: url('./assets/fonts/Cousine.ttf');
}

@font-face {
    font-family: 'Coustard';
    src: url('./assets/fonts/Coustard.ttf');
}

@font-face {
    font-family: 'Covered By Your Grace';
    src: url('./assets/fonts/Covered By Your Grace.ttf');
}

@font-face {
    font-family: 'Crafty Girls';
    src: url('./assets/fonts/Crafty Girls.ttf');
}

@font-face {
    font-family: 'Creepster';
    src: url('./assets/fonts/Creepster.ttf');
}

@font-face {
    font-family: 'Crete Round';
    src: url('./assets/fonts/Crete Round.ttf');
}

@font-face {
    font-family: 'Crimson Pro';
    src: url('./assets/fonts/Crimson Pro.ttf');
}

@font-face {
    font-family: 'Crimson Text';
    src: url('./assets/fonts/Crimson Text.ttf');
}

@font-face {
    font-family: 'Croissant One';
    src: url('./assets/fonts/Croissant One.ttf');
}

@font-face {
    font-family: 'Crushed';
    src: url('./assets/fonts/Crushed.ttf');
}

@font-face {
    font-family: 'Cuprum';
    src: url('./assets/fonts/Cuprum.ttf');
}

@font-face {
    font-family: 'Cute Font';
    src: url('./assets/fonts/Cute Font.ttf');
}

@font-face {
    font-family: 'Cutive Mono';
    src: url('./assets/fonts/Cutive Mono.ttf');
}

@font-face {
    font-family: 'Cutive';
    src: url('./assets/fonts/Cutive.ttf');
}

@font-face {
    font-family: 'Dai Banna SIL';
    src: url('./assets/fonts/Dai Banna SIL.ttf');
}

@font-face {
    font-family: 'Damion';
    src: url('./assets/fonts/Damion.ttf');
}

@font-face {
    font-family: 'Dancing Script';
    src: url('./assets/fonts/Dancing Script.ttf');
}

@font-face {
    font-family: 'Dangrek';
    src: url('./assets/fonts/Dangrek.ttf');
}

@font-face {
    font-family: 'Darker Grotesque';
    src: url('./assets/fonts/Darker Grotesque.ttf');
}

@font-face {
    font-family: 'Darumadrop One';
    src: url('./assets/fonts/Darumadrop One.ttf');
}

@font-face {
    font-family: 'David Libre';
    src: url('./assets/fonts/David Libre.ttf');
}

@font-face {
    font-family: 'Dawning of a New Day';
    src: url('./assets/fonts/Dawning of a New Day.ttf');
}

@font-face {
    font-family: 'Days One';
    src: url('./assets/fonts/Days One.ttf');
}

@font-face {
    font-family: 'Dekko';
    src: url('./assets/fonts/Dekko.ttf');
}

@font-face {
    font-family: 'Dela Gothic One';
    src: url('./assets/fonts/Dela Gothic One.ttf');
}

@font-face {
    font-family: 'Delicious Handrawn';
    src: url('./assets/fonts/Delicious Handrawn.ttf');
}

@font-face {
    font-family: 'Delius Swash Caps';
    src: url('./assets/fonts/Delius Swash Caps.ttf');
}

@font-face {
    font-family: 'Delius Unicase';
    src: url('./assets/fonts/Delius Unicase.ttf');
}

@font-face {
    font-family: 'Delius';
    src: url('./assets/fonts/Delius.ttf');
}

@font-face {
    font-family: 'Della Respira';
    src: url('./assets/fonts/Della Respira.ttf');
}

@font-face {
    font-family: 'Denk One';
    src: url('./assets/fonts/Denk One.ttf');
}

@font-face {
    font-family: 'Devonshire';
    src: url('./assets/fonts/Devonshire.ttf');
}

@font-face {
    font-family: 'Dhurjati';
    src: url('./assets/fonts/Dhurjati.ttf');
}

@font-face {
    font-family: 'Didact Gothic';
    src: url('./assets/fonts/Didact Gothic.ttf');
}

@font-face {
    font-family: 'Diphylleia';
    src: url('./assets/fonts/Diphylleia.ttf');
}

@font-face {
    font-family: 'Diplomata SC';
    src: url('./assets/fonts/Diplomata SC.ttf');
}

@font-face {
    font-family: 'Diplomata';
    src: url('./assets/fonts/Diplomata.ttf');
}

@font-face {
    font-family: 'DM Mono';
    src: url('./assets/fonts/DM Mono.ttf');
}

@font-face {
    font-family: 'DM Sans';
    src: url('./assets/fonts/DM Sans.ttf');
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('./assets/fonts/DM Serif Display.ttf');
}

@font-face {
    font-family: 'DM Serif Text';
    src: url('./assets/fonts/DM Serif Text.ttf');
}

@font-face {
    font-family: 'Do Hyeon';
    src: url('./assets/fonts/Do Hyeon.ttf');
}

@font-face {
    font-family: 'Dokdo';
    src: url('./assets/fonts/Dokdo.ttf');
}

@font-face {
    font-family: 'Domine';
    src: url('./assets/fonts/Domine.ttf');
}

@font-face {
    font-family: 'Donegal One';
    src: url('./assets/fonts/Donegal One.ttf');
}

@font-face {
    font-family: 'Dongle';
    src: url('./assets/fonts/Dongle.ttf');
}

@font-face {
    font-family: 'Doppio One';
    src: url('./assets/fonts/Doppio One.ttf');
}

@font-face {
    font-family: 'Dorsa';
    src: url('./assets/fonts/Dorsa.ttf');
}

@font-face {
    font-family: 'Dosis';
    src: url('./assets/fonts/Dosis.ttf');
}

@font-face {
    font-family: 'DotGothic16';
    src: url('./assets/fonts/DotGothic16.ttf');
}

@font-face {
    font-family: 'Dr Sugiyama';
    src: url('./assets/fonts/Dr Sugiyama.ttf');
}

@font-face {
    font-family: 'Duru Sans';
    src: url('./assets/fonts/Duru Sans.ttf');
}

@font-face {
    font-family: 'Dynalight';
    src: url('./assets/fonts/Dynalight.ttf');
}

@font-face {
    font-family: 'DynaPuff';
    src: url('./assets/fonts/DynaPuff.ttf');
}

@font-face {
    font-family: 'Eagle Lake';
    src: url('./assets/fonts/Eagle Lake.ttf');
}

@font-face {
    font-family: 'East Sea Dokdo';
    src: url('./assets/fonts/East Sea Dokdo.ttf');
}

@font-face {
    font-family: 'Eater';
    src: url('./assets/fonts/Eater.ttf');
}

@font-face {
    font-family: 'EB Garamond';
    src: url('./assets/fonts/EB Garamond.ttf');
}

@font-face {
    font-family: 'Economica';
    src: url('./assets/fonts/Economica.ttf');
}

@font-face {
    font-family: 'Eczar';
    src: url('./assets/fonts/Eczar.ttf');
}

@font-face {
    font-family: 'Edu NSW ACT Foundation';
    src: url('./assets/fonts/Edu NSW ACT Foundation.ttf');
}

@font-face {
    font-family: 'Edu QLD Beginner';
    src: url('./assets/fonts/Edu QLD Beginner.ttf');
}

@font-face {
    font-family: 'Edu SA Beginner';
    src: url('./assets/fonts/Edu SA Beginner.ttf');
}

@font-face {
    font-family: 'Edu TAS Beginner';
    src: url('./assets/fonts/Edu TAS Beginner.ttf');
}

@font-face {
    font-family: 'Edu VIC WA NT Beginner';
    src: url('./assets/fonts/Edu VIC WA NT Beginner.ttf');
}

@font-face {
    font-family: 'El Messiri';
    src: url('./assets/fonts/El Messiri.ttf');
}

@font-face {
    font-family: 'Electrolize';
    src: url('./assets/fonts/Electrolize.ttf');
}

@font-face {
    font-family: 'Elsie Swash Caps';
    src: url('./assets/fonts/Elsie Swash Caps.ttf');
}

@font-face {
    font-family: 'Elsie';
    src: url('./assets/fonts/Elsie.ttf');
}

@font-face {
    font-family: 'Emblema One';
    src: url('./assets/fonts/Emblema One.ttf');
}

@font-face {
    font-family: 'Emilys Candy';
    src: url('./assets/fonts/Emilys Candy.ttf');
}

@font-face {
    font-family: 'Encode Sans Condensed';
    src: url('./assets/fonts/Encode Sans Condensed.ttf');
}

@font-face {
    font-family: 'Encode Sans Expanded';
    src: url('./assets/fonts/Encode Sans Expanded.ttf');
}

@font-face {
    font-family: 'Encode Sans SC';
    src: url('./assets/fonts/Encode Sans SC.ttf');
}

@font-face {
    font-family: 'Encode Sans Semi Condensed';
    src: url('./assets/fonts/Encode Sans Semi Condensed.ttf');
}

@font-face {
    font-family: 'Encode Sans Semi Expanded';
    src: url('./assets/fonts/Encode Sans Semi Expanded.ttf');
}

@font-face {
    font-family: 'Encode Sans';
    src: url('./assets/fonts/Encode Sans.ttf');
}

@font-face {
    font-family: 'Engagement';
    src: url('./assets/fonts/Engagement.ttf');
}

@font-face {
    font-family: 'Englebert';
    src: url('./assets/fonts/Englebert.ttf');
}

@font-face {
    font-family: 'Enriqueta';
    src: url('./assets/fonts/Enriqueta.ttf');
}

@font-face {
    font-family: 'Ephesis';
    src: url('./assets/fonts/Ephesis.ttf');
}

@font-face {
    font-family: 'Epilogue';
    src: url('./assets/fonts/Epilogue.ttf');
}

@font-face {
    font-family: 'Erica One';
    src: url('./assets/fonts/Erica One.ttf');
}

@font-face {
    font-family: 'Esteban';
    src: url('./assets/fonts/Esteban.ttf');
}

@font-face {
    font-family: 'Estonia';
    src: url('./assets/fonts/Estonia.ttf');
}

@font-face {
    font-family: 'Euphoria Script';
    src: url('./assets/fonts/Euphoria Script.ttf');
}

@font-face {
    font-family: 'Ewert';
    src: url('./assets/fonts/Ewert.ttf');
}

@font-face {
    font-family: 'Exo 2';
    src: url('./assets/fonts/Exo 2.ttf');
}

@font-face {
    font-family: 'Exo';
    src: url('./assets/fonts/Exo.ttf');
}

@font-face {
    font-family: 'Expletus Sans';
    src: url('./assets/fonts/Expletus Sans.ttf');
}

@font-face {
    font-family: 'Explora';
    src: url('./assets/fonts/Explora.ttf');
}

@font-face {
    font-family: 'Fahkwang';
    src: url('./assets/fonts/Fahkwang.ttf');
}

@font-face {
    font-family: 'Familjen Grotesk';
    src: url('./assets/fonts/Familjen Grotesk.ttf');
}

@font-face {
    font-family: 'Fanwood Text';
    src: url('./assets/fonts/Fanwood Text.ttf');
}

@font-face {
    font-family: 'Farro';
    src: url('./assets/fonts/Farro.ttf');
}

@font-face {
    font-family: 'Farsan';
    src: url('./assets/fonts/Farsan.ttf');
}

@font-face {
    font-family: 'Fascinate Inline';
    src: url('./assets/fonts/Fascinate Inline.ttf');
}

@font-face {
    font-family: 'Fascinate';
    src: url('./assets/fonts/Fascinate.ttf');
}

@font-face {
    font-family: 'Faster One';
    src: url('./assets/fonts/Faster One.ttf');
}

@font-face {
    font-family: 'Fasthand';
    src: url('./assets/fonts/Fasthand.ttf');
}

@font-face {
    font-family: 'Fauna One';
    src: url('./assets/fonts/Fauna One.ttf');
}

@font-face {
    font-family: 'Faustina';
    src: url('./assets/fonts/Faustina.ttf');
}

@font-face {
    font-family: 'Federant';
    src: url('./assets/fonts/Federant.ttf');
}

@font-face {
    font-family: 'Federo';
    src: url('./assets/fonts/Federo.ttf');
}

@font-face {
    font-family: 'Felipa';
    src: url('./assets/fonts/Felipa.ttf');
}

@font-face {
    font-family: 'Fenix';
    src: url('./assets/fonts/Fenix.ttf');
}

@font-face {
    font-family: 'Festive';
    src: url('./assets/fonts/Festive.ttf');
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/fonts/Figtree.ttf');
}

@font-face {
    font-family: 'Finger Paint';
    src: url('./assets/fonts/Finger Paint.ttf');
}

@font-face {
    font-family: 'Finlandica';
    src: url('./assets/fonts/Finlandica.ttf');
}

@font-face {
    font-family: 'Fira Code';
    src: url('./assets/fonts/Fira Code.ttf');
}

@font-face {
    font-family: 'Fira Mono';
    src: url('./assets/fonts/Fira Mono.ttf');
}

@font-face {
    font-family: 'Fira Sans Condensed';
    src: url('./assets/fonts/Fira Sans Condensed.ttf');
}

@font-face {
    font-family: 'Fira Sans Extra Condensed';
    src: url('./assets/fonts/Fira Sans Extra Condensed.ttf');
}

@font-face {
    font-family: 'Fira Sans';
    src: url('./assets/fonts/Fira Sans.ttf');
}

@font-face {
    font-family: 'Fjalla One';
    src: url('./assets/fonts/Fjalla One.ttf');
}

@font-face {
    font-family: 'Fjord One';
    src: url('./assets/fonts/Fjord One.ttf');
}

@font-face {
    font-family: 'Flamenco';
    src: url('./assets/fonts/Flamenco.ttf');
}

@font-face {
    font-family: 'Flavors';
    src: url('./assets/fonts/Flavors.ttf');
}

@font-face {
    font-family: 'Fleur De Leah';
    src: url('./assets/fonts/Fleur De Leah.ttf');
}

@font-face {
    font-family: 'Flow Block';
    src: url('./assets/fonts/Flow Block.ttf');
}

@font-face {
    font-family: 'Flow Circular';
    src: url('./assets/fonts/Flow Circular.ttf');
}

@font-face {
    font-family: 'Flow Rounded';
    src: url('./assets/fonts/Flow Rounded.ttf');
}

@font-face {
    font-family: 'Foldit';
    src: url('./assets/fonts/Foldit.ttf');
}

@font-face {
    font-family: 'Fondamento';
    src: url('./assets/fonts/Fondamento.ttf');
}

@font-face {
    font-family: 'Fontdiner Swanky';
    src: url('./assets/fonts/Fontdiner Swanky.ttf');
}

@font-face {
    font-family: 'Forum';
    src: url('./assets/fonts/Forum.ttf');
}

@font-face {
    font-family: 'Fragment Mono';
    src: url('./assets/fonts/Fragment Mono.ttf');
}

@font-face {
    font-family: 'Francois One';
    src: url('./assets/fonts/Francois One.ttf');
}

@font-face {
    font-family: 'Frank Ruhl Libre';
    src: url('./assets/fonts/Frank Ruhl Libre.ttf');
}

@font-face {
    font-family: 'Fraunces';
    src: url('./assets/fonts/Fraunces.ttf');
}

@font-face {
    font-family: 'Freckle Face';
    src: url('./assets/fonts/Freckle Face.ttf');
}

@font-face {
    font-family: 'Fredericka the Great';
    src: url('./assets/fonts/Fredericka the Great.ttf');
}

@font-face {
    font-family: 'Fredoka';
    src: url('./assets/fonts/Fredoka.ttf');
}

@font-face {
    font-family: 'Freehand';
    src: url('./assets/fonts/Freehand.ttf');
}

@font-face {
    font-family: 'Fresca';
    src: url('./assets/fonts/Fresca.ttf');
}

@font-face {
    font-family: 'Frijole';
    src: url('./assets/fonts/Frijole.ttf');
}

@font-face {
    font-family: 'Fruktur';
    src: url('./assets/fonts/Fruktur.ttf');
}

@font-face {
    font-family: 'Fugaz One';
    src: url('./assets/fonts/Fugaz One.ttf');
}

@font-face {
    font-family: 'Fuggles';
    src: url('./assets/fonts/Fuggles.ttf');
}

@font-face {
    font-family: 'Fuzzy Bubbles';
    src: url('./assets/fonts/Fuzzy Bubbles.ttf');
}

@font-face {
    font-family: 'Gabarito';
    src: url('./assets/fonts/Gabarito.ttf');
}

@font-face {
    font-family: 'Gabriela';
    src: url('./assets/fonts/Gabriela.ttf');
}

@font-face {
    font-family: 'Gaegu';
    src: url('./assets/fonts/Gaegu.ttf');
}

@font-face {
    font-family: 'Gafata';
    src: url('./assets/fonts/Gafata.ttf');
}

@font-face {
    font-family: 'Gajraj One';
    src: url('./assets/fonts/Gajraj One.ttf');
}

@font-face {
    font-family: 'Galada';
    src: url('./assets/fonts/Galada.ttf');
}

@font-face {
    font-family: 'Galdeano';
    src: url('./assets/fonts/Galdeano.ttf');
}

@font-face {
    font-family: 'Galindo';
    src: url('./assets/fonts/Galindo.ttf');
}

@font-face {
    font-family: 'Gamja Flower';
    src: url('./assets/fonts/Gamja Flower.ttf');
}

@font-face {
    font-family: 'Gantari';
    src: url('./assets/fonts/Gantari.ttf');
}

@font-face {
    font-family: 'Gasoek One';
    src: url('./assets/fonts/Gasoek One.ttf');
}

@font-face {
    font-family: 'Gayathri';
    src: url('./assets/fonts/Gayathri.ttf');
}

@font-face {
    font-family: 'Gelasio';
    src: url('./assets/fonts/Gelasio.ttf');
}

@font-face {
    font-family: 'Gemunu Libre';
    src: url('./assets/fonts/Gemunu Libre.ttf');
}

@font-face {
    font-family: 'Genos';
    src: url('./assets/fonts/Genos.ttf');
}

@font-face {
    font-family: 'Gentium Book Plus';
    src: url('./assets/fonts/Gentium Book Plus.ttf');
}

@font-face {
    font-family: 'Gentium Plus';
    src: url('./assets/fonts/Gentium Plus.ttf');
}

@font-face {
    font-family: 'Geo';
    src: url('./assets/fonts/Geo.ttf');
}

@font-face {
    font-family: 'Geologica';
    src: url('./assets/fonts/Geologica.ttf');
}

@font-face {
    font-family: 'Georama';
    src: url('./assets/fonts/Georama.ttf');
}

@font-face {
    font-family: 'Geostar Fill';
    src: url('./assets/fonts/Geostar Fill.ttf');
}

@font-face {
    font-family: 'Geostar';
    src: url('./assets/fonts/Geostar.ttf');
}

@font-face {
    font-family: 'Germania One';
    src: url('./assets/fonts/Germania One.ttf');
}

@font-face {
    font-family: 'GFS Didot';
    src: url('./assets/fonts/GFS Didot.ttf');
}

@font-face {
    font-family: 'GFS Neohellenic';
    src: url('./assets/fonts/GFS Neohellenic.ttf');
}

@font-face {
    font-family: 'Gideon Roman';
    src: url('./assets/fonts/Gideon Roman.ttf');
}

@font-face {
    font-family: 'Gidugu';
    src: url('./assets/fonts/Gidugu.ttf');
}

@font-face {
    font-family: 'Gilda Display';
    src: url('./assets/fonts/Gilda Display.ttf');
}

@font-face {
    font-family: 'Girassol';
    src: url('./assets/fonts/Girassol.ttf');
}

@font-face {
    font-family: 'Give You Glory';
    src: url('./assets/fonts/Give You Glory.ttf');
}

@font-face {
    font-family: 'Glass Antiqua';
    src: url('./assets/fonts/Glass Antiqua.ttf');
}

@font-face {
    font-family: 'Glegoo';
    src: url('./assets/fonts/Glegoo.ttf');
}

@font-face {
    font-family: 'Gloock';
    src: url('./assets/fonts/Gloock.ttf');
}

@font-face {
    font-family: 'Gloria Hallelujah';
    src: url('./assets/fonts/Gloria Hallelujah.ttf');
}

@font-face {
    font-family: 'Glory';
    src: url('./assets/fonts/Glory.ttf');
}

@font-face {
    font-family: 'Gluten';
    src: url('./assets/fonts/Gluten.ttf');
}

@font-face {
    font-family: 'Goblin One';
    src: url('./assets/fonts/Goblin One.ttf');
}

@font-face {
    font-family: 'Gochi Hand';
    src: url('./assets/fonts/Gochi Hand.ttf');
}

@font-face {
    font-family: 'Goldman';
    src: url('./assets/fonts/Goldman.ttf');
}

@font-face {
    font-family: 'Golos Text';
    src: url('./assets/fonts/Golos Text.ttf');
}

@font-face {
    font-family: 'Gorditas';
    src: url('./assets/fonts/Gorditas.ttf');
}

@font-face {
    font-family: 'Gothic A1';
    src: url('./assets/fonts/Gothic A1.ttf');
}

@font-face {
    font-family: 'Gotu';
    src: url('./assets/fonts/Gotu.ttf');
}

@font-face {
    font-family: 'Goudy Bookletter 1911';
    src: url('./assets/fonts/Goudy Bookletter 1911.ttf');
}

@font-face {
    font-family: 'Gowun Batang';
    src: url('./assets/fonts/Gowun Batang.ttf');
}

@font-face {
    font-family: 'Gowun Dodum';
    src: url('./assets/fonts/Gowun Dodum.ttf');
}

@font-face {
    font-family: 'Graduate';
    src: url('./assets/fonts/Graduate.ttf');
}

@font-face {
    font-family: 'Grand Hotel';
    src: url('./assets/fonts/Grand Hotel.ttf');
}

@font-face {
    font-family: 'Grandiflora One';
    src: url('./assets/fonts/Grandiflora One.ttf');
}

@font-face {
    font-family: 'Grandstander';
    src: url('./assets/fonts/Grandstander.ttf');
}

@font-face {
    font-family: 'Grape Nuts';
    src: url('./assets/fonts/Grape Nuts.ttf');
}

@font-face {
    font-family: 'Gravitas One';
    src: url('./assets/fonts/Gravitas One.ttf');
}

@font-face {
    font-family: 'Great Vibes';
    src: url('./assets/fonts/Great Vibes.ttf');
}

@font-face {
    font-family: 'Grechen Fuemen';
    src: url('./assets/fonts/Grechen Fuemen.ttf');
}

@font-face {
    font-family: 'Grenze Gotisch';
    src: url('./assets/fonts/Grenze Gotisch.ttf');
}

@font-face {
    font-family: 'Grenze';
    src: url('./assets/fonts/Grenze.ttf');
}

@font-face {
    font-family: 'Grey Qo';
    src: url('./assets/fonts/Grey Qo.ttf');
}

@font-face {
    font-family: 'Griffy';
    src: url('./assets/fonts/Griffy.ttf');
}

@font-face {
    font-family: 'Gruppo';
    src: url('./assets/fonts/Gruppo.ttf');
}

@font-face {
    font-family: 'Gudea';
    src: url('./assets/fonts/Gudea.ttf');
}

@font-face {
    font-family: 'Gugi';
    src: url('./assets/fonts/Gugi.ttf');
}

@font-face {
    font-family: 'Gulzar';
    src: url('./assets/fonts/Gulzar.ttf');
}

@font-face {
    font-family: 'Gupter';
    src: url('./assets/fonts/Gupter.ttf');
}

@font-face {
    font-family: 'Gurajada';
    src: url('./assets/fonts/Gurajada.ttf');
}

@font-face {
    font-family: 'Gwendolyn';
    src: url('./assets/fonts/Gwendolyn.ttf');
}

@font-face {
    font-family: 'Habibi';
    src: url('./assets/fonts/Habibi.ttf');
}

@font-face {
    font-family: 'Hachi Maru Pop';
    src: url('./assets/fonts/Hachi Maru Pop.ttf');
}

@font-face {
    font-family: 'Hahmlet';
    src: url('./assets/fonts/Hahmlet.ttf');
}

@font-face {
    font-family: 'Halant';
    src: url('./assets/fonts/Halant.ttf');
}

@font-face {
    font-family: 'Hammersmith One';
    src: url('./assets/fonts/Hammersmith One.ttf');
}

@font-face {
    font-family: 'Hanalei Fill';
    src: url('./assets/fonts/Hanalei Fill.ttf');
}

@font-face {
    font-family: 'Hanalei';
    src: url('./assets/fonts/Hanalei.ttf');
}

@font-face {
    font-family: 'Handjet';
    src: url('./assets/fonts/Handjet.ttf');
}

@font-face {
    font-family: 'Handlee';
    src: url('./assets/fonts/Handlee.ttf');
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('./assets/fonts/Hanken Grotesk.ttf');
}

@font-face {
    font-family: 'Hanuman';
    src: url('./assets/fonts/Hanuman.ttf');
}

@font-face {
    font-family: 'Happy Monkey';
    src: url('./assets/fonts/Happy Monkey.ttf');
}

@font-face {
    font-family: 'Harmattan';
    src: url('./assets/fonts/Harmattan.ttf');
}

@font-face {
    font-family: 'Headland One';
    src: url('./assets/fonts/Headland One.ttf');
}

@font-face {
    font-family: 'Hedvig Letters Sans';
    src: url('./assets/fonts/Hedvig Letters Sans.ttf');
}

@font-face {
    font-family: 'Hedvig Letters Serif';
    src: url('./assets/fonts/Hedvig Letters Serif.ttf');
}

@font-face {
    font-family: 'Heebo';
    src: url('./assets/fonts/Heebo.ttf');
}

@font-face {
    font-family: 'Henny Penny';
    src: url('./assets/fonts/Henny Penny.ttf');
}

@font-face {
    font-family: 'Hepta Slab';
    src: url('./assets/fonts/Hepta Slab.ttf');
}

@font-face {
    font-family: 'Herr Von Muellerhoff';
    src: url('./assets/fonts/Herr Von Muellerhoff.ttf');
}

@font-face {
    font-family: 'Hi Melody';
    src: url('./assets/fonts/Hi Melody.ttf');
}

@font-face {
    font-family: 'Hina Mincho';
    src: url('./assets/fonts/Hina Mincho.ttf');
}

@font-face {
    font-family: 'Hind Guntur';
    src: url('./assets/fonts/Hind Guntur.ttf');
}

@font-face {
    font-family: 'Hind Madurai';
    src: url('./assets/fonts/Hind Madurai.ttf');
}

@font-face {
    font-family: 'Hind Siliguri';
    src: url('./assets/fonts/Hind Siliguri.ttf');
}

@font-face {
    font-family: 'Hind Vadodara';
    src: url('./assets/fonts/Hind Vadodara.ttf');
}

@font-face {
    font-family: 'Hind';
    src: url('./assets/fonts/Hind.ttf');
}

@font-face {
    font-family: 'Holtwood One SC';
    src: url('./assets/fonts/Holtwood One SC.ttf');
}

@font-face {
    font-family: 'Homemade Apple';
    src: url('./assets/fonts/Homemade Apple.ttf');
}

@font-face {
    font-family: 'Homenaje';
    src: url('./assets/fonts/Homenaje.ttf');
}

@font-face {
    font-family: 'Honk';
    src: url('./assets/fonts/Honk.ttf');
}

@font-face {
    font-family: 'Hubballi';
    src: url('./assets/fonts/Hubballi.ttf');
}

@font-face {
    font-family: 'Hurricane';
    src: url('./assets/fonts/Hurricane.ttf');
}

@font-face {
    font-family: 'Ibarra Real Nova';
    src: url('./assets/fonts/Ibarra Real Nova.ttf');
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('./assets/fonts/IBM Plex Mono.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans Arabic';
    src: url('./assets/fonts/IBM Plex Sans Arabic.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans Condensed';
    src: url('./assets/fonts/IBM Plex Sans Condensed.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans Devanagari';
    src: url('./assets/fonts/IBM Plex Sans Devanagari.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans Hebrew';
    src: url('./assets/fonts/IBM Plex Sans Hebrew.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans JP';
    src: url('./assets/fonts/IBM Plex Sans JP.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans KR';
    src: url('./assets/fonts/IBM Plex Sans KR.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans Thai Looped';
    src: url('./assets/fonts/IBM Plex Sans Thai Looped.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans Thai';
    src: url('./assets/fonts/IBM Plex Sans Thai.ttf');
}

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('./assets/fonts/IBM Plex Sans.ttf');
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('./assets/fonts/IBM Plex Serif.ttf');
}

@font-face {
    font-family: 'Iceberg';
    src: url('./assets/fonts/Iceberg.ttf');
}

@font-face {
    font-family: 'Iceland';
    src: url('./assets/fonts/Iceland.ttf');
}

@font-face {
    font-family: 'IM Fell Double Pica SC';
    src: url('./assets/fonts/IM Fell Double Pica SC.ttf');
}

@font-face {
    font-family: 'IM Fell Double Pica';
    src: url('./assets/fonts/IM Fell Double Pica.ttf');
}

@font-face {
    font-family: 'IM Fell DW Pica SC';
    src: url('./assets/fonts/IM Fell DW Pica SC.ttf');
}

@font-face {
    font-family: 'IM Fell DW Pica';
    src: url('./assets/fonts/IM Fell DW Pica.ttf');
}

@font-face {
    font-family: 'IM Fell English SC';
    src: url('./assets/fonts/IM Fell English SC.ttf');
}

@font-face {
    font-family: 'IM Fell English';
    src: url('./assets/fonts/IM Fell English.ttf');
}

@font-face {
    font-family: 'IM Fell French Canon SC';
    src: url('./assets/fonts/IM Fell French Canon SC.ttf');
}

@font-face {
    font-family: 'IM Fell French Canon';
    src: url('./assets/fonts/IM Fell French Canon.ttf');
}

@font-face {
    font-family: 'IM Fell Great Primer SC';
    src: url('./assets/fonts/IM Fell Great Primer SC.ttf');
}

@font-face {
    font-family: 'IM Fell Great Primer';
    src: url('./assets/fonts/IM Fell Great Primer.ttf');
}

@font-face {
    font-family: 'Imbue';
    src: url('./assets/fonts/Imbue.ttf');
}

@font-face {
    font-family: 'Imperial Script';
    src: url('./assets/fonts/Imperial Script.ttf');
}

@font-face {
    font-family: 'Imprima';
    src: url('./assets/fonts/Imprima.ttf');
}

@font-face {
    font-family: 'Inclusive Sans';
    src: url('./assets/fonts/Inclusive Sans.ttf');
}

@font-face {
    font-family: 'Inconsolata';
    src: url('./assets/fonts/Inconsolata.ttf');
}

@font-face {
    font-family: 'Inder';
    src: url('./assets/fonts/Inder.ttf');
}

@font-face {
    font-family: 'Indie Flower';
    src: url('./assets/fonts/Indie Flower.ttf');
}

@font-face {
    font-family: 'Ingrid Darling';
    src: url('./assets/fonts/Ingrid Darling.ttf');
}

@font-face {
    font-family: 'Inika';
    src: url('./assets/fonts/Inika.ttf');
}

@font-face {
    font-family: 'Inknut Antiqua';
    src: url('./assets/fonts/Inknut Antiqua.ttf');
}

@font-face {
    font-family: 'Inria Sans';
    src: url('./assets/fonts/Inria Sans.ttf');
}

@font-face {
    font-family: 'Inria Serif';
    src: url('./assets/fonts/Inria Serif.ttf');
}

@font-face {
    font-family: 'Inspiration';
    src: url('./assets/fonts/Inspiration.ttf');
}

@font-face {
    font-family: 'Instrument Sans';
    src: url('./assets/fonts/Instrument Sans.ttf');
}

@font-face {
    font-family: 'Instrument Serif';
    src: url('./assets/fonts/Instrument Serif.ttf');
}

@font-face {
    font-family: 'Inter Tight';
    src: url('./assets/fonts/Inter Tight.ttf');
}

@font-face {
    font-family: 'Inter';
    src: url('./assets/fonts/Inter.ttf');
}

@font-face {
    font-family: 'Irish Grover';
    src: url('./assets/fonts/Irish Grover.ttf');
}

@font-face {
    font-family: 'Island Moments';
    src: url('./assets/fonts/Island Moments.ttf');
}

@font-face {
    font-family: 'Istok Web';
    src: url('./assets/fonts/Istok Web.ttf');
}

@font-face {
    font-family: 'Italiana';
    src: url('./assets/fonts/Italiana.ttf');
}

@font-face {
    font-family: 'Italianno';
    src: url('./assets/fonts/Italianno.ttf');
}

@font-face {
    font-family: 'Itim';
    src: url('./assets/fonts/Itim.ttf');
}

@font-face {
    font-family: 'Jacquarda Bastarda 9';
    src: url('./assets/fonts/Jacquarda Bastarda 9.ttf');
}

@font-face {
    font-family: 'Jacques Francois Shadow';
    src: url('./assets/fonts/Jacques Francois Shadow.ttf');
}

@font-face {
    font-family: 'Jacques Francois';
    src: url('./assets/fonts/Jacques Francois.ttf');
}

@font-face {
    font-family: 'Jaldi';
    src: url('./assets/fonts/Jaldi.ttf');
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('./assets/fonts/JetBrains Mono.ttf');
}

@font-face {
    font-family: 'Jim Nightshade';
    src: url('./assets/fonts/Jim Nightshade.ttf');
}

@font-face {
    font-family: 'Joan';
    src: url('./assets/fonts/Joan.ttf');
}

@font-face {
    font-family: 'Jockey One';
    src: url('./assets/fonts/Jockey One.ttf');
}

@font-face {
    font-family: 'Jolly Lodger';
    src: url('./assets/fonts/Jolly Lodger.ttf');
}

@font-face {
    font-family: 'Jomhuria';
    src: url('./assets/fonts/Jomhuria.ttf');
}

@font-face {
    font-family: 'Jomolhari';
    src: url('./assets/fonts/Jomolhari.ttf');
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('./assets/fonts/Josefin Sans.ttf');
}

@font-face {
    font-family: 'Josefin Slab';
    src: url('./assets/fonts/Josefin Slab.ttf');
}

@font-face {
    font-family: 'Jost';
    src: url('./assets/fonts/Jost.ttf');
}

@font-face {
    font-family: 'Joti One';
    src: url('./assets/fonts/Joti One.ttf');
}

@font-face {
    font-family: 'Jua';
    src: url('./assets/fonts/Jua.ttf');
}

@font-face {
    font-family: 'Judson';
    src: url('./assets/fonts/Judson.ttf');
}

@font-face {
    font-family: 'Julee';
    src: url('./assets/fonts/Julee.ttf');
}

@font-face {
    font-family: 'Julius Sans One';
    src: url('./assets/fonts/Julius Sans One.ttf');
}

@font-face {
    font-family: 'Junge';
    src: url('./assets/fonts/Junge.ttf');
}

@font-face {
    font-family: 'Jura';
    src: url('./assets/fonts/Jura.ttf');
}

@font-face {
    font-family: 'Just Another Hand';
    src: url('./assets/fonts/Just Another Hand.ttf');
}

@font-face {
    font-family: 'Just Me Again Down Here';
    src: url('./assets/fonts/Just Me Again Down Here.ttf');
}

@font-face {
    font-family: 'K2D';
    src: url('./assets/fonts/K2D.ttf');
}

@font-face {
    font-family: 'Kablammo';
    src: url('./assets/fonts/Kablammo.ttf');
}

@font-face {
    font-family: 'Kadwa';
    src: url('./assets/fonts/Kadwa.ttf');
}

@font-face {
    font-family: 'Kaisei Decol';
    src: url('./assets/fonts/Kaisei Decol.ttf');
}

@font-face {
    font-family: 'Kaisei HarunoUmi';
    src: url('./assets/fonts/Kaisei HarunoUmi.ttf');
}

@font-face {
    font-family: 'Kaisei Opti';
    src: url('./assets/fonts/Kaisei Opti.ttf');
}

@font-face {
    font-family: 'Kaisei Tokumin';
    src: url('./assets/fonts/Kaisei Tokumin.ttf');
}

@font-face {
    font-family: 'Kalam';
    src: url('./assets/fonts/Kalam.ttf');
}

@font-face {
    font-family: 'Kalnia';
    src: url('./assets/fonts/Kalnia.ttf');
}

@font-face {
    font-family: 'Kameron';
    src: url('./assets/fonts/Kameron.ttf');
}

@font-face {
    font-family: 'Kanit';
    src: url('./assets/fonts/Kanit.ttf');
}

@font-face {
    font-family: 'Kantumruy Pro';
    src: url('./assets/fonts/Kantumruy Pro.ttf');
}

@font-face {
    font-family: 'Karantina';
    src: url('./assets/fonts/Karantina.ttf');
}

@font-face {
    font-family: 'Karla';
    src: url('./assets/fonts/Karla.ttf');
}

@font-face {
    font-family: 'Karma';
    src: url('./assets/fonts/Karma.ttf');
}

@font-face {
    font-family: 'Katibeh';
    src: url('./assets/fonts/Katibeh.ttf');
}

@font-face {
    font-family: 'Kaushan Script';
    src: url('./assets/fonts/Kaushan Script.ttf');
}

@font-face {
    font-family: 'Kavivanar';
    src: url('./assets/fonts/Kavivanar.ttf');
}

@font-face {
    font-family: 'Kavoon';
    src: url('./assets/fonts/Kavoon.ttf');
}

@font-face {
    font-family: 'Kay Pho Du';
    src: url('./assets/fonts/Kay Pho Du.ttf');
}

@font-face {
    font-family: 'Kdam Thmor Pro';
    src: url('./assets/fonts/Kdam Thmor Pro.ttf');
}

@font-face {
    font-family: 'Keania One';
    src: url('./assets/fonts/Keania One.ttf');
}

@font-face {
    font-family: 'Kelly Slab';
    src: url('./assets/fonts/Kelly Slab.ttf');
}

@font-face {
    font-family: 'Kenia';
    src: url('./assets/fonts/Kenia.ttf');
}

@font-face {
    font-family: 'Khand';
    src: url('./assets/fonts/Khand.ttf');
}

@font-face {
    font-family: 'Khmer';
    src: url('./assets/fonts/Khmer.ttf');
}

@font-face {
    font-family: 'Khula';
    src: url('./assets/fonts/Khula.ttf');
}

@font-face {
    font-family: 'Kings';
    src: url('./assets/fonts/Kings.ttf');
}

@font-face {
    font-family: 'Kirang Haerang';
    src: url('./assets/fonts/Kirang Haerang.ttf');
}

@font-face {
    font-family: 'Kite One';
    src: url('./assets/fonts/Kite One.ttf');
}

@font-face {
    font-family: 'Kiwi Maru';
    src: url('./assets/fonts/Kiwi Maru.ttf');
}

@font-face {
    font-family: 'Klee One';
    src: url('./assets/fonts/Klee One.ttf');
}

@font-face {
    font-family: 'Knewave';
    src: url('./assets/fonts/Knewave.ttf');
}

@font-face {
    font-family: 'Kodchasan';
    src: url('./assets/fonts/Kodchasan.ttf');
}

@font-face {
    font-family: 'Kode Mono';
    src: url('./assets/fonts/Kode Mono.ttf');
}

@font-face {
    font-family: 'Koh Santepheap';
    src: url('./assets/fonts/Koh Santepheap.ttf');
}

@font-face {
    font-family: 'KoHo';
    src: url('./assets/fonts/KoHo.ttf');
}

@font-face {
    font-family: 'Kolker Brush';
    src: url('./assets/fonts/Kolker Brush.ttf');
}

@font-face {
    font-family: 'Konkhmer Sleokchher';
    src: url('./assets/fonts/Konkhmer Sleokchher.ttf');
}

@font-face {
    font-family: 'Kosugi Maru';
    src: url('./assets/fonts/Kosugi Maru.ttf');
}

@font-face {
    font-family: 'Kosugi';
    src: url('./assets/fonts/Kosugi.ttf');
}

@font-face {
    font-family: 'Kotta One';
    src: url('./assets/fonts/Kotta One.ttf');
}

@font-face {
    font-family: 'Koulen';
    src: url('./assets/fonts/Koulen.ttf');
}

@font-face {
    font-family: 'Kranky';
    src: url('./assets/fonts/Kranky.ttf');
}

@font-face {
    font-family: 'Kreon';
    src: url('./assets/fonts/Kreon.ttf');
}

@font-face {
    font-family: 'Kristi';
    src: url('./assets/fonts/Kristi.ttf');
}

@font-face {
    font-family: 'Krona One';
    src: url('./assets/fonts/Krona One.ttf');
}

@font-face {
    font-family: 'Krub';
    src: url('./assets/fonts/Krub.ttf');
}

@font-face {
    font-family: 'Kufam';
    src: url('./assets/fonts/Kufam.ttf');
}

@font-face {
    font-family: 'Kulim Park';
    src: url('./assets/fonts/Kulim Park.ttf');
}

@font-face {
    font-family: 'Kumar One Outline';
    src: url('./assets/fonts/Kumar One Outline.ttf');
}

@font-face {
    font-family: 'Kumar One';
    src: url('./assets/fonts/Kumar One.ttf');
}

@font-face {
    font-family: 'Kumbh Sans';
    src: url('./assets/fonts/Kumbh Sans.ttf');
}

@font-face {
    font-family: 'Kurale';
    src: url('./assets/fonts/Kurale.ttf');
}

@font-face {
    font-family: 'La Belle Aurore';
    src: url('./assets/fonts/La Belle Aurore.ttf');
}

@font-face {
    font-family: 'Labrada';
    src: url('./assets/fonts/Labrada.ttf');
}

@font-face {
    font-family: 'Lacquer';
    src: url('./assets/fonts/Lacquer.ttf');
}

@font-face {
    font-family: 'Laila';
    src: url('./assets/fonts/Laila.ttf');
}

@font-face {
    font-family: 'Lakki Reddy';
    src: url('./assets/fonts/Lakki Reddy.ttf');
}

@font-face {
    font-family: 'Lalezar';
    src: url('./assets/fonts/Lalezar.ttf');
}

@font-face {
    font-family: 'Lancelot';
    src: url('./assets/fonts/Lancelot.ttf');
}

@font-face {
    font-family: 'Langar';
    src: url('./assets/fonts/Langar.ttf');
}

@font-face {
    font-family: 'Lateef';
    src: url('./assets/fonts/Lateef.ttf');
}

@font-face {
    font-family: 'Lato';
    src: url('./assets/fonts/Lato.ttf');
}

@font-face {
    font-family: 'Lavishly Yours';
    src: url('./assets/fonts/Lavishly Yours.ttf');
}

@font-face {
    font-family: 'League Gothic';
    src: url('./assets/fonts/League Gothic.ttf');
}

@font-face {
    font-family: 'League Script';
    src: url('./assets/fonts/League Script.ttf');
}

@font-face {
    font-family: 'League Spartan';
    src: url('./assets/fonts/League Spartan.ttf');
}

@font-face {
    font-family: 'Leckerli One';
    src: url('./assets/fonts/Leckerli One.ttf');
}

@font-face {
    font-family: 'Ledger';
    src: url('./assets/fonts/Ledger.ttf');
}

@font-face {
    font-family: 'Lekton';
    src: url('./assets/fonts/Lekton.ttf');
}

@font-face {
    font-family: 'Lemon';
    src: url('./assets/fonts/Lemon.ttf');
}

@font-face {
    font-family: 'Lemonada';
    src: url('./assets/fonts/Lemonada.ttf');
}

@font-face {
    font-family: 'Lexend Deca';
    src: url('./assets/fonts/Lexend Deca.ttf');
}

@font-face {
    font-family: 'Lexend Exa';
    src: url('./assets/fonts/Lexend Exa.ttf');
}

@font-face {
    font-family: 'Lexend Giga';
    src: url('./assets/fonts/Lexend Giga.ttf');
}

@font-face {
    font-family: 'Lexend Mega';
    src: url('./assets/fonts/Lexend Mega.ttf');
}

@font-face {
    font-family: 'Lexend Peta';
    src: url('./assets/fonts/Lexend Peta.ttf');
}

@font-face {
    font-family: 'Lexend Tera';
    src: url('./assets/fonts/Lexend Tera.ttf');
}

@font-face {
    font-family: 'Lexend Zetta';
    src: url('./assets/fonts/Lexend Zetta.ttf');
}

@font-face {
    font-family: 'Lexend';
    src: url('./assets/fonts/Lexend.ttf');
}

@font-face {
    font-family: 'Libre Barcode 128 Text';
    src: url('./assets/fonts/Libre Barcode 128 Text.ttf');
}

@font-face {
    font-family: 'Libre Barcode 128';
    src: url('./assets/fonts/Libre Barcode 128.ttf');
}

@font-face {
    font-family: 'Libre Barcode 39 Extended Text';
    src: url('./assets/fonts/Libre Barcode 39 Extended Text.ttf');
}

@font-face {
    font-family: 'Libre Barcode 39 Extended';
    src: url('./assets/fonts/Libre Barcode 39 Extended.ttf');
}

@font-face {
    font-family: 'Libre Barcode 39 Text';
    src: url('./assets/fonts/Libre Barcode 39 Text.ttf');
}

@font-face {
    font-family: 'Libre Barcode 39';
    src: url('./assets/fonts/Libre Barcode 39.ttf');
}

@font-face {
    font-family: 'Libre Barcode EAN13 Text';
    src: url('./assets/fonts/Libre Barcode EAN13 Text.ttf');
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('./assets/fonts/Libre Baskerville.ttf');
}

@font-face {
    font-family: 'Libre Bodoni';
    src: url('./assets/fonts/Libre Bodoni.ttf');
}

@font-face {
    font-family: 'Libre Caslon Display';
    src: url('./assets/fonts/Libre Caslon Display.ttf');
}

@font-face {
    font-family: 'Libre Caslon Text';
    src: url('./assets/fonts/Libre Caslon Text.ttf');
}

@font-face {
    font-family: 'Libre Franklin';
    src: url('./assets/fonts/Libre Franklin.ttf');
}

@font-face {
    font-family: 'Licorice';
    src: url('./assets/fonts/Licorice.ttf');
}

@font-face {
    font-family: 'Life Savers';
    src: url('./assets/fonts/Life Savers.ttf');
}

@font-face {
    font-family: 'Lilita One';
    src: url('./assets/fonts/Lilita One.ttf');
}

@font-face {
    font-family: 'Lily Script One';
    src: url('./assets/fonts/Lily Script One.ttf');
}

@font-face {
    font-family: 'Limelight';
    src: url('./assets/fonts/Limelight.ttf');
}

@font-face {
    font-family: 'Linden Hill';
    src: url('./assets/fonts/Linden Hill.ttf');
}

@font-face {
    font-family: 'Linefont';
    src: url('./assets/fonts/Linefont.ttf');
}

@font-face {
    font-family: 'Lisu Bosa';
    src: url('./assets/fonts/Lisu Bosa.ttf');
}

@font-face {
    font-family: 'Literata';
    src: url('./assets/fonts/Literata.ttf');
}

@font-face {
    font-family: 'Liu Jian Mao Cao';
    src: url('./assets/fonts/Liu Jian Mao Cao.ttf');
}

@font-face {
    font-family: 'Livvic';
    src: url('./assets/fonts/Livvic.ttf');
}

@font-face {
    font-family: 'Lobster Two';
    src: url('./assets/fonts/Lobster Two.ttf');
}

@font-face {
    font-family: 'Lobster';
    src: url('./assets/fonts/Lobster.ttf');
}

@font-face {
    font-family: 'Londrina Outline';
    src: url('./assets/fonts/Londrina Outline.ttf');
}

@font-face {
    font-family: 'Londrina Shadow';
    src: url('./assets/fonts/Londrina Shadow.ttf');
}

@font-face {
    font-family: 'Londrina Sketch';
    src: url('./assets/fonts/Londrina Sketch.ttf');
}

@font-face {
    font-family: 'Londrina Solid';
    src: url('./assets/fonts/Londrina Solid.ttf');
}

@font-face {
    font-family: 'Long Cang';
    src: url('./assets/fonts/Long Cang.ttf');
}

@font-face {
    font-family: 'Lora';
    src: url('./assets/fonts/Lora.ttf');
}

@font-face {
    font-family: 'Love Light';
    src: url('./assets/fonts/Love Light.ttf');
}

@font-face {
    font-family: 'Love Ya Like A Sister';
    src: url('./assets/fonts/Love Ya Like A Sister.ttf');
}

@font-face {
    font-family: 'Loved by the King';
    src: url('./assets/fonts/Loved by the King.ttf');
}

@font-face {
    font-family: 'Lovers Quarrel';
    src: url('./assets/fonts/Lovers Quarrel.ttf');
}

@font-face {
    font-family: 'Luckiest Guy';
    src: url('./assets/fonts/Luckiest Guy.ttf');
}

@font-face {
    font-family: 'Lugrasimo';
    src: url('./assets/fonts/Lugrasimo.ttf');
}

@font-face {
    font-family: 'Lumanosimo';
    src: url('./assets/fonts/Lumanosimo.ttf');
}

@font-face {
    font-family: 'Lunasima';
    src: url('./assets/fonts/Lunasima.ttf');
}

@font-face {
    font-family: 'Lusitana';
    src: url('./assets/fonts/Lusitana.ttf');
}

@font-face {
    font-family: 'Lustria';
    src: url('./assets/fonts/Lustria.ttf');
}

@font-face {
    font-family: 'Luxurious Roman';
    src: url('./assets/fonts/Luxurious Roman.ttf');
}

@font-face {
    font-family: 'Luxurious Script';
    src: url('./assets/fonts/Luxurious Script.ttf');
}

@font-face {
    font-family: 'M PLUS 1 Code';
    src: url('./assets/fonts/M PLUS 1 Code.ttf');
}

@font-face {
    font-family: 'M PLUS 1';
    src: url('./assets/fonts/M PLUS 1.ttf');
}

@font-face {
    font-family: 'M PLUS 1p';
    src: url('./assets/fonts/M PLUS 1p.ttf');
}

@font-face {
    font-family: 'M PLUS 2';
    src: url('./assets/fonts/M PLUS 2.ttf');
}

@font-face {
    font-family: 'M PLUS Code Latin';
    src: url('./assets/fonts/M PLUS Code Latin.ttf');
}

@font-face {
    font-family: 'M PLUS Rounded 1c';
    src: url('./assets/fonts/M PLUS Rounded 1c.ttf');
}

@font-face {
    font-family: 'Ma Shan Zheng';
    src: url('./assets/fonts/Ma Shan Zheng.ttf');
}

@font-face {
    font-family: 'Macondo Swash Caps';
    src: url('./assets/fonts/Macondo Swash Caps.ttf');
}

@font-face {
    font-family: 'Macondo';
    src: url('./assets/fonts/Macondo.ttf');
}

@font-face {
    font-family: 'Mada';
    src: url('./assets/fonts/Mada.ttf');
}

@font-face {
    font-family: 'Madimi One';
    src: url('./assets/fonts/Madimi One.ttf');
}

@font-face {
    font-family: 'Magra';
    src: url('./assets/fonts/Magra.ttf');
}

@font-face {
    font-family: 'Maiden Orange';
    src: url('./assets/fonts/Maiden Orange.ttf');
}

@font-face {
    font-family: 'Maitree';
    src: url('./assets/fonts/Maitree.ttf');
}

@font-face {
    font-family: 'Major Mono Display';
    src: url('./assets/fonts/Major Mono Display.ttf');
}

@font-face {
    font-family: 'Mako';
    src: url('./assets/fonts/Mako.ttf');
}

@font-face {
    font-family: 'Mali';
    src: url('./assets/fonts/Mali.ttf');
}

@font-face {
    font-family: 'Mallanna';
    src: url('./assets/fonts/Mallanna.ttf');
}

@font-face {
    font-family: 'Mandali';
    src: url('./assets/fonts/Mandali.ttf');
}

@font-face {
    font-family: 'Manjari';
    src: url('./assets/fonts/Manjari.ttf');
}

@font-face {
    font-family: 'Manrope';
    src: url('./assets/fonts/Manrope.ttf');
}

@font-face {
    font-family: 'Mansalva';
    src: url('./assets/fonts/Mansalva.ttf');
}

@font-face {
    font-family: 'Manuale';
    src: url('./assets/fonts/Manuale.ttf');
}

@font-face {
    font-family: 'Marcellus SC';
    src: url('./assets/fonts/Marcellus SC.ttf');
}

@font-face {
    font-family: 'Marcellus';
    src: url('./assets/fonts/Marcellus.ttf');
}

@font-face {
    font-family: 'Marck Script';
    src: url('./assets/fonts/Marck Script.ttf');
}

@font-face {
    font-family: 'Margarine';
    src: url('./assets/fonts/Margarine.ttf');
}

@font-face {
    font-family: 'Marhey';
    src: url('./assets/fonts/Marhey.ttf');
}

@font-face {
    font-family: 'Markazi Text';
    src: url('./assets/fonts/Markazi Text.ttf');
}

@font-face {
    font-family: 'Marko One';
    src: url('./assets/fonts/Marko One.ttf');
}

@font-face {
    font-family: 'Marmelad';
    src: url('./assets/fonts/Marmelad.ttf');
}

@font-face {
    font-family: 'Martel Sans';
    src: url('./assets/fonts/Martel Sans.ttf');
}

@font-face {
    font-family: 'Martel';
    src: url('./assets/fonts/Martel.ttf');
}

@font-face {
    font-family: 'Martian Mono';
    src: url('./assets/fonts/Martian Mono.ttf');
}

@font-face {
    font-family: 'Marvel';
    src: url('./assets/fonts/Marvel.ttf');
}

@font-face {
    font-family: 'Mate SC';
    src: url('./assets/fonts/Mate SC.ttf');
}

@font-face {
    font-family: 'Mate';
    src: url('./assets/fonts/Mate.ttf');
}

@font-face {
    font-family: 'Material Icons Outlined';
    src: url('./assets/fonts/Material Icons Outlined.ttf');
}

@font-face {
    font-family: 'Material Icons Round';
    src: url('./assets/fonts/Material Icons Round.ttf');
}

@font-face {
    font-family: 'Material Icons Sharp';
    src: url('./assets/fonts/Material Icons Sharp.ttf');
}

@font-face {
    font-family: 'Material Icons Two Tone';
    src: url('./assets/fonts/Material Icons Two Tone.ttf');
}

@font-face {
    font-family: 'Material Icons';
    src: url('./assets/fonts/Material Icons.ttf');
}

@font-face {
    font-family: 'Material Symbols Outlined';
    src: url('./assets/fonts/Material Symbols Outlined.ttf');
}

@font-face {
    font-family: 'Material Symbols Rounded';
    src: url('./assets/fonts/Material Symbols Rounded.ttf');
}

@font-face {
    font-family: 'Material Symbols Sharp';
    src: url('./assets/fonts/Material Symbols Sharp.ttf');
}

@font-face {
    font-family: 'Maven Pro';
    src: url('./assets/fonts/Maven Pro.ttf');
}

@font-face {
    font-family: 'McLaren';
    src: url('./assets/fonts/McLaren.ttf');
}

@font-face {
    font-family: 'Mea Culpa';
    src: url('./assets/fonts/Mea Culpa.ttf');
}

@font-face {
    font-family: 'Meddon';
    src: url('./assets/fonts/Meddon.ttf');
}

@font-face {
    font-family: 'MedievalSharp';
    src: url('./assets/fonts/MedievalSharp.ttf');
}

@font-face {
    font-family: 'Medula One';
    src: url('./assets/fonts/Medula One.ttf');
}

@font-face {
    font-family: 'Meera Inimai';
    src: url('./assets/fonts/Meera Inimai.ttf');
}

@font-face {
    font-family: 'Megrim';
    src: url('./assets/fonts/Megrim.ttf');
}

@font-face {
    font-family: 'Meie Script';
    src: url('./assets/fonts/Meie Script.ttf');
}

@font-face {
    font-family: 'Meow Script';
    src: url('./assets/fonts/Meow Script.ttf');
}

@font-face {
    font-family: 'Merienda';
    src: url('./assets/fonts/Merienda.ttf');
}

@font-face {
    font-family: 'Merriweather Sans';
    src: url('./assets/fonts/Merriweather Sans.ttf');
}

@font-face {
    font-family: 'Merriweather';
    src: url('./assets/fonts/Merriweather.ttf');
}

@font-face {
    font-family: 'Metal Mania';
    src: url('./assets/fonts/Metal Mania.ttf');
}

@font-face {
    font-family: 'Metal';
    src: url('./assets/fonts/Metal.ttf');
}

@font-face {
    font-family: 'Metamorphous';
    src: url('./assets/fonts/Metamorphous.ttf');
}

@font-face {
    font-family: 'Metrophobic';
    src: url('./assets/fonts/Metrophobic.ttf');
}

@font-face {
    font-family: 'Michroma';
    src: url('./assets/fonts/Michroma.ttf');
}

@font-face {
    font-family: 'Micro 5';
    src: url('./assets/fonts/Micro 5.ttf');
}

@font-face {
    font-family: 'Milonga';
    src: url('./assets/fonts/Milonga.ttf');
}

@font-face {
    font-family: 'Miltonian Tattoo';
    src: url('./assets/fonts/Miltonian Tattoo.ttf');
}

@font-face {
    font-family: 'Miltonian';
    src: url('./assets/fonts/Miltonian.ttf');
}

@font-face {
    font-family: 'Mina';
    src: url('./assets/fonts/Mina.ttf');
}

@font-face {
    font-family: 'Mingzat';
    src: url('./assets/fonts/Mingzat.ttf');
}

@font-face {
    font-family: 'Miniver';
    src: url('./assets/fonts/Miniver.ttf');
}

@font-face {
    font-family: 'Miriam Libre';
    src: url('./assets/fonts/Miriam Libre.ttf');
}

@font-face {
    font-family: 'Mirza';
    src: url('./assets/fonts/Mirza.ttf');
}

@font-face {
    font-family: 'Miss Fajardose';
    src: url('./assets/fonts/Miss Fajardose.ttf');
}

@font-face {
    font-family: 'Mitr';
    src: url('./assets/fonts/Mitr.ttf');
}

@font-face {
    font-family: 'Mochiy Pop One';
    src: url('./assets/fonts/Mochiy Pop One.ttf');
}

@font-face {
    font-family: 'Mochiy Pop P One';
    src: url('./assets/fonts/Mochiy Pop P One.ttf');
}

@font-face {
    font-family: 'Modak';
    src: url('./assets/fonts/Modak.ttf');
}

@font-face {
    font-family: 'Modern Antiqua';
    src: url('./assets/fonts/Modern Antiqua.ttf');
}

@font-face {
    font-family: 'Mogra';
    src: url('./assets/fonts/Mogra.ttf');
}

@font-face {
    font-family: 'Mohave';
    src: url('./assets/fonts/Mohave.ttf');
}

@font-face {
    font-family: 'Moirai One';
    src: url('./assets/fonts/Moirai One.ttf');
}

@font-face {
    font-family: 'Molengo';
    src: url('./assets/fonts/Molengo.ttf');
}

@font-face {
    font-family: 'Molle';
    src: url('./assets/fonts/Molle.ttf');
}

@font-face {
    font-family: 'Monda';
    src: url('./assets/fonts/Monda.ttf');
}

@font-face {
    font-family: 'Monofett';
    src: url('./assets/fonts/Monofett.ttf');
}

@font-face {
    font-family: 'Monomaniac One';
    src: url('./assets/fonts/Monomaniac One.ttf');
}

@font-face {
    font-family: 'Monoton';
    src: url('./assets/fonts/Monoton.ttf');
}

@font-face {
    font-family: 'Monsieur La Doulaise';
    src: url('./assets/fonts/Monsieur La Doulaise.ttf');
}

@font-face {
    font-family: 'Montaga';
    src: url('./assets/fonts/Montaga.ttf');
}

@font-face {
    font-family: 'Montagu Slab';
    src: url('./assets/fonts/Montagu Slab.ttf');
}

@font-face {
    font-family: 'MonteCarlo';
    src: url('./assets/fonts/MonteCarlo.ttf');
}

@font-face {
    font-family: 'Montez';
    src: url('./assets/fonts/Montez.ttf');
}

@font-face {
    font-family: 'Montserrat Alternates';
    src: url('./assets/fonts/Montserrat Alternates.ttf');
}

@font-face {
    font-family: 'Montserrat Subrayada';
    src: url('./assets/fonts/Montserrat Subrayada.ttf');
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat.ttf');
}

@font-face {
    font-family: 'Moo Lah Lah';
    src: url('./assets/fonts/Moo Lah Lah.ttf');
}

@font-face {
    font-family: 'Mooli';
    src: url('./assets/fonts/Mooli.ttf');
}

@font-face {
    font-family: 'Moon Dance';
    src: url('./assets/fonts/Moon Dance.ttf');
}

@font-face {
    font-family: 'Moul';
    src: url('./assets/fonts/Moul.ttf');
}

@font-face {
    font-family: 'Moulpali';
    src: url('./assets/fonts/Moulpali.ttf');
}

@font-face {
    font-family: 'Mountains of Christmas';
    src: url('./assets/fonts/Mountains of Christmas.ttf');
}

@font-face {
    font-family: 'Mouse Memoirs';
    src: url('./assets/fonts/Mouse Memoirs.ttf');
}

@font-face {
    font-family: 'Mr Bedfort';
    src: url('./assets/fonts/Mr Bedfort.ttf');
}

@font-face {
    font-family: 'Mr Dafoe';
    src: url('./assets/fonts/Mr Dafoe.ttf');
}

@font-face {
    font-family: 'Mr De Haviland';
    src: url('./assets/fonts/Mr De Haviland.ttf');
}

@font-face {
    font-family: 'Mrs Saint Delafield';
    src: url('./assets/fonts/Mrs Saint Delafield.ttf');
}

@font-face {
    font-family: 'Mrs Sheppards';
    src: url('./assets/fonts/Mrs Sheppards.ttf');
}

@font-face {
    font-family: 'Ms Madi';
    src: url('./assets/fonts/Ms Madi.ttf');
}

@font-face {
    font-family: 'Mukta Mahee';
    src: url('./assets/fonts/Mukta Mahee.ttf');
}

@font-face {
    font-family: 'Mukta Malar';
    src: url('./assets/fonts/Mukta Malar.ttf');
}

@font-face {
    font-family: 'Mukta Vaani';
    src: url('./assets/fonts/Mukta Vaani.ttf');
}

@font-face {
    font-family: 'Mukta';
    src: url('./assets/fonts/Mukta.ttf');
}

@font-face {
    font-family: 'Mulish';
    src: url('./assets/fonts/Mulish.ttf');
}

@font-face {
    font-family: 'Murecho';
    src: url('./assets/fonts/Murecho.ttf');
}

@font-face {
    font-family: 'MuseoModerno';
    src: url('./assets/fonts/MuseoModerno.ttf');
}

@font-face {
    font-family: 'My Soul';
    src: url('./assets/fonts/My Soul.ttf');
}

@font-face {
    font-family: 'Mynerve';
    src: url('./assets/fonts/Mynerve.ttf');
}

@font-face {
    font-family: 'Mystery Quest';
    src: url('./assets/fonts/Mystery Quest.ttf');
}

@font-face {
    font-family: 'Nabla';
    src: url('./assets/fonts/Nabla.ttf');
}

@font-face {
    font-family: 'Namdhinggo';
    src: url('./assets/fonts/Namdhinggo.ttf');
}

@font-face {
    font-family: 'Nanum Brush Script';
    src: url('./assets/fonts/Nanum Brush Script.ttf');
}

@font-face {
    font-family: 'Nanum Gothic Coding';
    src: url('./assets/fonts/Nanum Gothic Coding.ttf');
}

@font-face {
    font-family: 'Nanum Gothic';
    src: url('./assets/fonts/Nanum Gothic.ttf');
}

@font-face {
    font-family: 'Nanum Myeongjo';
    src: url('./assets/fonts/Nanum Myeongjo.ttf');
}

@font-face {
    font-family: 'Nanum Pen Script';
    src: url('./assets/fonts/Nanum Pen Script.ttf');
}

@font-face {
    font-family: 'Narnoor';
    src: url('./assets/fonts/Narnoor.ttf');
}

@font-face {
    font-family: 'Neonderthaw';
    src: url('./assets/fonts/Neonderthaw.ttf');
}

@font-face {
    font-family: 'Nerko One';
    src: url('./assets/fonts/Nerko One.ttf');
}

@font-face {
    font-family: 'Neucha';
    src: url('./assets/fonts/Neucha.ttf');
}

@font-face {
    font-family: 'Neuton';
    src: url('./assets/fonts/Neuton.ttf');
}

@font-face {
    font-family: 'New Rocker';
    src: url('./assets/fonts/New Rocker.ttf');
}

@font-face {
    font-family: 'New Tegomin';
    src: url('./assets/fonts/New Tegomin.ttf');
}

@font-face {
    font-family: 'News Cycle';
    src: url('./assets/fonts/News Cycle.ttf');
}

@font-face {
    font-family: 'Newsreader';
    src: url('./assets/fonts/Newsreader.ttf');
}

@font-face {
    font-family: 'Niconne';
    src: url('./assets/fonts/Niconne.ttf');
}

@font-face {
    font-family: 'Niramit';
    src: url('./assets/fonts/Niramit.ttf');
}

@font-face {
    font-family: 'Nixie One';
    src: url('./assets/fonts/Nixie One.ttf');
}

@font-face {
    font-family: 'Nobile';
    src: url('./assets/fonts/Nobile.ttf');
}

@font-face {
    font-family: 'Nokora';
    src: url('./assets/fonts/Nokora.ttf');
}

@font-face {
    font-family: 'Norican';
    src: url('./assets/fonts/Norican.ttf');
}

@font-face {
    font-family: 'Nosifer';
    src: url('./assets/fonts/Nosifer.ttf');
}

@font-face {
    font-family: 'Notable';
    src: url('./assets/fonts/Notable.ttf');
}

@font-face {
    font-family: 'Nothing You Could Do';
    src: url('./assets/fonts/Nothing You Could Do.ttf');
}

@font-face {
    font-family: 'Noticia Text';
    src: url('./assets/fonts/Noticia Text.ttf');
}

@font-face {
    font-family: 'Noto Color Emoji';
    src: url('./assets/fonts/Noto Color Emoji.ttf');
}

@font-face {
    font-family: 'Noto Emoji';
    src: url('./assets/fonts/Noto Emoji.ttf');
}

@font-face {
    font-family: 'Noto Kufi Arabic';
    src: url('./assets/fonts/Noto Kufi Arabic.ttf');
}

@font-face {
    font-family: 'Noto Music';
    src: url('./assets/fonts/Noto Music.ttf');
}

@font-face {
    font-family: 'Noto Naskh Arabic';
    src: url('./assets/fonts/Noto Naskh Arabic.ttf');
}

@font-face {
    font-family: 'Noto Nastaliq Urdu';
    src: url('./assets/fonts/Noto Nastaliq Urdu.ttf');
}

@font-face {
    font-family: 'Noto Rashi Hebrew';
    src: url('./assets/fonts/Noto Rashi Hebrew.ttf');
}

@font-face {
    font-family: 'Noto Sans Adlam Unjoined';
    src: url('./assets/fonts/Noto Sans Adlam Unjoined.ttf');
}

@font-face {
    font-family: 'Noto Sans Adlam';
    src: url('./assets/fonts/Noto Sans Adlam.ttf');
}

@font-face {
    font-family: 'Noto Sans Anatolian Hieroglyphs';
    src: url('./assets/fonts/Noto Sans Anatolian Hieroglyphs.ttf');
}

@font-face {
    font-family: 'Noto Sans Arabic';
    src: url('./assets/fonts/Noto Sans Arabic.ttf');
}

@font-face {
    font-family: 'Noto Sans Armenian';
    src: url('./assets/fonts/Noto Sans Armenian.ttf');
}

@font-face {
    font-family: 'Noto Sans Avestan';
    src: url('./assets/fonts/Noto Sans Avestan.ttf');
}

@font-face {
    font-family: 'Noto Sans Balinese';
    src: url('./assets/fonts/Noto Sans Balinese.ttf');
}

@font-face {
    font-family: 'Noto Sans Bamum';
    src: url('./assets/fonts/Noto Sans Bamum.ttf');
}

@font-face {
    font-family: 'Noto Sans Bassa Vah';
    src: url('./assets/fonts/Noto Sans Bassa Vah.ttf');
}

@font-face {
    font-family: 'Noto Sans Batak';
    src: url('./assets/fonts/Noto Sans Batak.ttf');
}

@font-face {
    font-family: 'Noto Sans Bengali';
    src: url('./assets/fonts/Noto Sans Bengali.ttf');
}

@font-face {
    font-family: 'Noto Sans Bhaiksuki';
    src: url('./assets/fonts/Noto Sans Bhaiksuki.ttf');
}

@font-face {
    font-family: 'Noto Sans Brahmi';
    src: url('./assets/fonts/Noto Sans Brahmi.ttf');
}

@font-face {
    font-family: 'Noto Sans Buginese';
    src: url('./assets/fonts/Noto Sans Buginese.ttf');
}

@font-face {
    font-family: 'Noto Sans Buhid';
    src: url('./assets/fonts/Noto Sans Buhid.ttf');
}

@font-face {
    font-family: 'Noto Sans Canadian Aboriginal';
    src: url('./assets/fonts/Noto Sans Canadian Aboriginal.ttf');
}

@font-face {
    font-family: 'Noto Sans Carian';
    src: url('./assets/fonts/Noto Sans Carian.ttf');
}

@font-face {
    font-family: 'Noto Sans Caucasian Albanian';
    src: url('./assets/fonts/Noto Sans Caucasian Albanian.ttf');
}

@font-face {
    font-family: 'Noto Sans Chakma';
    src: url('./assets/fonts/Noto Sans Chakma.ttf');
}

@font-face {
    font-family: 'Noto Sans Cham';
    src: url('./assets/fonts/Noto Sans Cham.ttf');
}

@font-face {
    font-family: 'Noto Sans Cherokee';
    src: url('./assets/fonts/Noto Sans Cherokee.ttf');
}

@font-face {
    font-family: 'Noto Sans Chorasmian';
    src: url('./assets/fonts/Noto Sans Chorasmian.ttf');
}

@font-face {
    font-family: 'Noto Sans Coptic';
    src: url('./assets/fonts/Noto Sans Coptic.ttf');
}

@font-face {
    font-family: 'Noto Sans Cuneiform';
    src: url('./assets/fonts/Noto Sans Cuneiform.ttf');
}

@font-face {
    font-family: 'Noto Sans Cypriot';
    src: url('./assets/fonts/Noto Sans Cypriot.ttf');
}

@font-face {
    font-family: 'Noto Sans Cypro Minoan';
    src: url('./assets/fonts/Noto Sans Cypro Minoan.ttf');
}

@font-face {
    font-family: 'Noto Sans Deseret';
    src: url('./assets/fonts/Noto Sans Deseret.ttf');
}

@font-face {
    font-family: 'Noto Sans Devanagari';
    src: url('./assets/fonts/Noto Sans Devanagari.ttf');
}

@font-face {
    font-family: 'Noto Sans Display';
    src: url('./assets/fonts/Noto Sans Display.ttf');
}

@font-face {
    font-family: 'Noto Sans Duployan';
    src: url('./assets/fonts/Noto Sans Duployan.ttf');
}

@font-face {
    font-family: 'Noto Sans Egyptian Hieroglyphs';
    src: url('./assets/fonts/Noto Sans Egyptian Hieroglyphs.ttf');
}

@font-face {
    font-family: 'Noto Sans Elbasan';
    src: url('./assets/fonts/Noto Sans Elbasan.ttf');
}

@font-face {
    font-family: 'Noto Sans Elymaic';
    src: url('./assets/fonts/Noto Sans Elymaic.ttf');
}

@font-face {
    font-family: 'Noto Sans Ethiopic';
    src: url('./assets/fonts/Noto Sans Ethiopic.ttf');
}

@font-face {
    font-family: 'Noto Sans Georgian';
    src: url('./assets/fonts/Noto Sans Georgian.ttf');
}

@font-face {
    font-family: 'Noto Sans Glagolitic';
    src: url('./assets/fonts/Noto Sans Glagolitic.ttf');
}

@font-face {
    font-family: 'Noto Sans Gothic';
    src: url('./assets/fonts/Noto Sans Gothic.ttf');
}

@font-face {
    font-family: 'Noto Sans Grantha';
    src: url('./assets/fonts/Noto Sans Grantha.ttf');
}

@font-face {
    font-family: 'Noto Sans Gujarati';
    src: url('./assets/fonts/Noto Sans Gujarati.ttf');
}

@font-face {
    font-family: 'Noto Sans Gunjala Gondi';
    src: url('./assets/fonts/Noto Sans Gunjala Gondi.ttf');
}

@font-face {
    font-family: 'Noto Sans Gurmukhi';
    src: url('./assets/fonts/Noto Sans Gurmukhi.ttf');
}

@font-face {
    font-family: 'Noto Sans Hanifi Rohingya';
    src: url('./assets/fonts/Noto Sans Hanifi Rohingya.ttf');
}

@font-face {
    font-family: 'Noto Sans Hanunoo';
    src: url('./assets/fonts/Noto Sans Hanunoo.ttf');
}

@font-face {
    font-family: 'Noto Sans Hatran';
    src: url('./assets/fonts/Noto Sans Hatran.ttf');
}

@font-face {
    font-family: 'Noto Sans Hebrew';
    src: url('./assets/fonts/Noto Sans Hebrew.ttf');
}

@font-face {
    font-family: 'Noto Sans HK';
    src: url('./assets/fonts/Noto Sans HK.ttf');
}

@font-face {
    font-family: 'Noto Sans Imperial Aramaic';
    src: url('./assets/fonts/Noto Sans Imperial Aramaic.ttf');
}

@font-face {
    font-family: 'Noto Sans Indic Siyaq Numbers';
    src: url('./assets/fonts/Noto Sans Indic Siyaq Numbers.ttf');
}

@font-face {
    font-family: 'Noto Sans Inscriptional Pahlavi';
    src: url('./assets/fonts/Noto Sans Inscriptional Pahlavi.ttf');
}

@font-face {
    font-family: 'Noto Sans Inscriptional Parthian';
    src: url('./assets/fonts/Noto Sans Inscriptional Parthian.ttf');
}

@font-face {
    font-family: 'Noto Sans Javanese';
    src: url('./assets/fonts/Noto Sans Javanese.ttf');
}

@font-face {
    font-family: 'Noto Sans JP';
    src: url('./assets/fonts/Noto Sans JP.ttf');
}

@font-face {
    font-family: 'Noto Sans Kaithi';
    src: url('./assets/fonts/Noto Sans Kaithi.ttf');
}

@font-face {
    font-family: 'Noto Sans Kannada';
    src: url('./assets/fonts/Noto Sans Kannada.ttf');
}

@font-face {
    font-family: 'Noto Sans Kawi';
    src: url('./assets/fonts/Noto Sans Kawi.ttf');
}

@font-face {
    font-family: 'Noto Sans Kayah Li';
    src: url('./assets/fonts/Noto Sans Kayah Li.ttf');
}

@font-face {
    font-family: 'Noto Sans Kharoshthi';
    src: url('./assets/fonts/Noto Sans Kharoshthi.ttf');
}

@font-face {
    font-family: 'Noto Sans Khmer';
    src: url('./assets/fonts/Noto Sans Khmer.ttf');
}

@font-face {
    font-family: 'Noto Sans Khojki';
    src: url('./assets/fonts/Noto Sans Khojki.ttf');
}

@font-face {
    font-family: 'Noto Sans Khudawadi';
    src: url('./assets/fonts/Noto Sans Khudawadi.ttf');
}

@font-face {
    font-family: 'Noto Sans KR';
    src: url('./assets/fonts/Noto Sans KR.ttf');
}

@font-face {
    font-family: 'Noto Sans Lao Looped';
    src: url('./assets/fonts/Noto Sans Lao Looped.ttf');
}

@font-face {
    font-family: 'Noto Sans Lao';
    src: url('./assets/fonts/Noto Sans Lao.ttf');
}

@font-face {
    font-family: 'Noto Sans Lepcha';
    src: url('./assets/fonts/Noto Sans Lepcha.ttf');
}

@font-face {
    font-family: 'Noto Sans Limbu';
    src: url('./assets/fonts/Noto Sans Limbu.ttf');
}

@font-face {
    font-family: 'Noto Sans Linear A';
    src: url('./assets/fonts/Noto Sans Linear A.ttf');
}

@font-face {
    font-family: 'Noto Sans Linear B';
    src: url('./assets/fonts/Noto Sans Linear B.ttf');
}

@font-face {
    font-family: 'Noto Sans Lisu';
    src: url('./assets/fonts/Noto Sans Lisu.ttf');
}

@font-face {
    font-family: 'Noto Sans Lycian';
    src: url('./assets/fonts/Noto Sans Lycian.ttf');
}

@font-face {
    font-family: 'Noto Sans Lydian';
    src: url('./assets/fonts/Noto Sans Lydian.ttf');
}

@font-face {
    font-family: 'Noto Sans Mahajani';
    src: url('./assets/fonts/Noto Sans Mahajani.ttf');
}

@font-face {
    font-family: 'Noto Sans Malayalam';
    src: url('./assets/fonts/Noto Sans Malayalam.ttf');
}

@font-face {
    font-family: 'Noto Sans Mandaic';
    src: url('./assets/fonts/Noto Sans Mandaic.ttf');
}

@font-face {
    font-family: 'Noto Sans Manichaean';
    src: url('./assets/fonts/Noto Sans Manichaean.ttf');
}

@font-face {
    font-family: 'Noto Sans Marchen';
    src: url('./assets/fonts/Noto Sans Marchen.ttf');
}

@font-face {
    font-family: 'Noto Sans Masaram Gondi';
    src: url('./assets/fonts/Noto Sans Masaram Gondi.ttf');
}

@font-face {
    font-family: 'Noto Sans Math';
    src: url('./assets/fonts/Noto Sans Math.ttf');
}

@font-face {
    font-family: 'Noto Sans Mayan Numerals';
    src: url('./assets/fonts/Noto Sans Mayan Numerals.ttf');
}

@font-face {
    font-family: 'Noto Sans Medefaidrin';
    src: url('./assets/fonts/Noto Sans Medefaidrin.ttf');
}

@font-face {
    font-family: 'Noto Sans Meetei Mayek';
    src: url('./assets/fonts/Noto Sans Meetei Mayek.ttf');
}

@font-face {
    font-family: 'Noto Sans Mende Kikakui';
    src: url('./assets/fonts/Noto Sans Mende Kikakui.ttf');
}

@font-face {
    font-family: 'Noto Sans Meroitic';
    src: url('./assets/fonts/Noto Sans Meroitic.ttf');
}

@font-face {
    font-family: 'Noto Sans Miao';
    src: url('./assets/fonts/Noto Sans Miao.ttf');
}

@font-face {
    font-family: 'Noto Sans Modi';
    src: url('./assets/fonts/Noto Sans Modi.ttf');
}

@font-face {
    font-family: 'Noto Sans Mongolian';
    src: url('./assets/fonts/Noto Sans Mongolian.ttf');
}

@font-face {
    font-family: 'Noto Sans Mono';
    src: url('./assets/fonts/Noto Sans Mono.ttf');
}

@font-face {
    font-family: 'Noto Sans Mro';
    src: url('./assets/fonts/Noto Sans Mro.ttf');
}

@font-face {
    font-family: 'Noto Sans Multani';
    src: url('./assets/fonts/Noto Sans Multani.ttf');
}

@font-face {
    font-family: 'Noto Sans Myanmar';
    src: url('./assets/fonts/Noto Sans Myanmar.ttf');
}

@font-face {
    font-family: 'Noto Sans Nabataean';
    src: url('./assets/fonts/Noto Sans Nabataean.ttf');
}

@font-face {
    font-family: 'Noto Sans Nag Mundari';
    src: url('./assets/fonts/Noto Sans Nag Mundari.ttf');
}

@font-face {
    font-family: 'Noto Sans Nandinagari';
    src: url('./assets/fonts/Noto Sans Nandinagari.ttf');
}

@font-face {
    font-family: 'Noto Sans New Tai Lue';
    src: url('./assets/fonts/Noto Sans New Tai Lue.ttf');
}

@font-face {
    font-family: 'Noto Sans Newa';
    src: url('./assets/fonts/Noto Sans Newa.ttf');
}

@font-face {
    font-family: 'Noto Sans NKo Unjoined';
    src: url('./assets/fonts/Noto Sans NKo Unjoined.ttf');
}

@font-face {
    font-family: 'Noto Sans NKo';
    src: url('./assets/fonts/Noto Sans NKo.ttf');
}

@font-face {
    font-family: 'Noto Sans Nushu';
    src: url('./assets/fonts/Noto Sans Nushu.ttf');
}

@font-face {
    font-family: 'Noto Sans Ogham';
    src: url('./assets/fonts/Noto Sans Ogham.ttf');
}

@font-face {
    font-family: 'Noto Sans Ol Chiki';
    src: url('./assets/fonts/Noto Sans Ol Chiki.ttf');
}

@font-face {
    font-family: 'Noto Sans Old Hungarian';
    src: url('./assets/fonts/Noto Sans Old Hungarian.ttf');
}

@font-face {
    font-family: 'Noto Sans Old Italic';
    src: url('./assets/fonts/Noto Sans Old Italic.ttf');
}

@font-face {
    font-family: 'Noto Sans Old North Arabian';
    src: url('./assets/fonts/Noto Sans Old North Arabian.ttf');
}

@font-face {
    font-family: 'Noto Sans Old Permic';
    src: url('./assets/fonts/Noto Sans Old Permic.ttf');
}

@font-face {
    font-family: 'Noto Sans Old Persian';
    src: url('./assets/fonts/Noto Sans Old Persian.ttf');
}

@font-face {
    font-family: 'Noto Sans Old Sogdian';
    src: url('./assets/fonts/Noto Sans Old Sogdian.ttf');
}

@font-face {
    font-family: 'Noto Sans Old South Arabian';
    src: url('./assets/fonts/Noto Sans Old South Arabian.ttf');
}

@font-face {
    font-family: 'Noto Sans Old Turkic';
    src: url('./assets/fonts/Noto Sans Old Turkic.ttf');
}

@font-face {
    font-family: 'Noto Sans Oriya';
    src: url('./assets/fonts/Noto Sans Oriya.ttf');
}

@font-face {
    font-family: 'Noto Sans Osage';
    src: url('./assets/fonts/Noto Sans Osage.ttf');
}

@font-face {
    font-family: 'Noto Sans Osmanya';
    src: url('./assets/fonts/Noto Sans Osmanya.ttf');
}

@font-face {
    font-family: 'Noto Sans Pahawh Hmong';
    src: url('./assets/fonts/Noto Sans Pahawh Hmong.ttf');
}

@font-face {
    font-family: 'Noto Sans Palmyrene';
    src: url('./assets/fonts/Noto Sans Palmyrene.ttf');
}

@font-face {
    font-family: 'Noto Sans Pau Cin Hau';
    src: url('./assets/fonts/Noto Sans Pau Cin Hau.ttf');
}

@font-face {
    font-family: 'Noto Sans Phags Pa';
    src: url('./assets/fonts/Noto Sans Phags Pa.ttf');
}

@font-face {
    font-family: 'Noto Sans Phoenician';
    src: url('./assets/fonts/Noto Sans Phoenician.ttf');
}

@font-face {
    font-family: 'Noto Sans Psalter Pahlavi';
    src: url('./assets/fonts/Noto Sans Psalter Pahlavi.ttf');
}

@font-face {
    font-family: 'Noto Sans Rejang';
    src: url('./assets/fonts/Noto Sans Rejang.ttf');
}

@font-face {
    font-family: 'Noto Sans Runic';
    src: url('./assets/fonts/Noto Sans Runic.ttf');
}

@font-face {
    font-family: 'Noto Sans Samaritan';
    src: url('./assets/fonts/Noto Sans Samaritan.ttf');
}

@font-face {
    font-family: 'Noto Sans Saurashtra';
    src: url('./assets/fonts/Noto Sans Saurashtra.ttf');
}

@font-face {
    font-family: 'Noto Sans SC';
    src: url('./assets/fonts/Noto Sans SC.ttf');
}

@font-face {
    font-family: 'Noto Sans Sharada';
    src: url('./assets/fonts/Noto Sans Sharada.ttf');
}

@font-face {
    font-family: 'Noto Sans Shavian';
    src: url('./assets/fonts/Noto Sans Shavian.ttf');
}

@font-face {
    font-family: 'Noto Sans Siddham';
    src: url('./assets/fonts/Noto Sans Siddham.ttf');
}

@font-face {
    font-family: 'Noto Sans SignWriting';
    src: url('./assets/fonts/Noto Sans SignWriting.ttf');
}

@font-face {
    font-family: 'Noto Sans Sinhala';
    src: url('./assets/fonts/Noto Sans Sinhala.ttf');
}

@font-face {
    font-family: 'Noto Sans Sogdian';
    src: url('./assets/fonts/Noto Sans Sogdian.ttf');
}

@font-face {
    font-family: 'Noto Sans Sora Sompeng';
    src: url('./assets/fonts/Noto Sans Sora Sompeng.ttf');
}

@font-face {
    font-family: 'Noto Sans Soyombo';
    src: url('./assets/fonts/Noto Sans Soyombo.ttf');
}

@font-face {
    font-family: 'Noto Sans Sundanese';
    src: url('./assets/fonts/Noto Sans Sundanese.ttf');
}

@font-face {
    font-family: 'Noto Sans Syloti Nagri';
    src: url('./assets/fonts/Noto Sans Syloti Nagri.ttf');
}

@font-face {
    font-family: 'Noto Sans Symbols 2';
    src: url('./assets/fonts/Noto Sans Symbols 2.ttf');
}

@font-face {
    font-family: 'Noto Sans Symbols';
    src: url('./assets/fonts/Noto Sans Symbols.ttf');
}

@font-face {
    font-family: 'Noto Sans Syriac Eastern';
    src: url('./assets/fonts/Noto Sans Syriac Eastern.ttf');
}

@font-face {
    font-family: 'Noto Sans Syriac';
    src: url('./assets/fonts/Noto Sans Syriac.ttf');
}

@font-face {
    font-family: 'Noto Sans Tagalog';
    src: url('./assets/fonts/Noto Sans Tagalog.ttf');
}

@font-face {
    font-family: 'Noto Sans Tagbanwa';
    src: url('./assets/fonts/Noto Sans Tagbanwa.ttf');
}

@font-face {
    font-family: 'Noto Sans Tai Le';
    src: url('./assets/fonts/Noto Sans Tai Le.ttf');
}

@font-face {
    font-family: 'Noto Sans Tai Tham';
    src: url('./assets/fonts/Noto Sans Tai Tham.ttf');
}

@font-face {
    font-family: 'Noto Sans Tai Viet';
    src: url('./assets/fonts/Noto Sans Tai Viet.ttf');
}

@font-face {
    font-family: 'Noto Sans Takri';
    src: url('./assets/fonts/Noto Sans Takri.ttf');
}

@font-face {
    font-family: 'Noto Sans Tamil Supplement';
    src: url('./assets/fonts/Noto Sans Tamil Supplement.ttf');
}

@font-face {
    font-family: 'Noto Sans Tamil';
    src: url('./assets/fonts/Noto Sans Tamil.ttf');
}

@font-face {
    font-family: 'Noto Sans Tangsa';
    src: url('./assets/fonts/Noto Sans Tangsa.ttf');
}

@font-face {
    font-family: 'Noto Sans TC';
    src: url('./assets/fonts/Noto Sans TC.ttf');
}

@font-face {
    font-family: 'Noto Sans Telugu';
    src: url('./assets/fonts/Noto Sans Telugu.ttf');
}

@font-face {
    font-family: 'Noto Sans Thaana';
    src: url('./assets/fonts/Noto Sans Thaana.ttf');
}

@font-face {
    font-family: 'Noto Sans Thai Looped';
    src: url('./assets/fonts/Noto Sans Thai Looped.ttf');
}

@font-face {
    font-family: 'Noto Sans Thai';
    src: url('./assets/fonts/Noto Sans Thai.ttf');
}

@font-face {
    font-family: 'Noto Sans Tifinagh';
    src: url('./assets/fonts/Noto Sans Tifinagh.ttf');
}

@font-face {
    font-family: 'Noto Sans Tirhuta';
    src: url('./assets/fonts/Noto Sans Tirhuta.ttf');
}

@font-face {
    font-family: 'Noto Sans Ugaritic';
    src: url('./assets/fonts/Noto Sans Ugaritic.ttf');
}

@font-face {
    font-family: 'Noto Sans Vai';
    src: url('./assets/fonts/Noto Sans Vai.ttf');
}

@font-face {
    font-family: 'Noto Sans Vithkuqi';
    src: url('./assets/fonts/Noto Sans Vithkuqi.ttf');
}

@font-face {
    font-family: 'Noto Sans Wancho';
    src: url('./assets/fonts/Noto Sans Wancho.ttf');
}

@font-face {
    font-family: 'Noto Sans Warang Citi';
    src: url('./assets/fonts/Noto Sans Warang Citi.ttf');
}

@font-face {
    font-family: 'Noto Sans Yi';
    src: url('./assets/fonts/Noto Sans Yi.ttf');
}

@font-face {
    font-family: 'Noto Sans Zanabazar Square';
    src: url('./assets/fonts/Noto Sans Zanabazar Square.ttf');
}

@font-face {
    font-family: 'Noto Sans';
    src: url('./assets/fonts/Noto Sans.ttf');
}

@font-face {
    font-family: 'Noto Serif Ahom';
    src: url('./assets/fonts/Noto Serif Ahom.ttf');
}

@font-face {
    font-family: 'Noto Serif Armenian';
    src: url('./assets/fonts/Noto Serif Armenian.ttf');
}

@font-face {
    font-family: 'Noto Serif Balinese';
    src: url('./assets/fonts/Noto Serif Balinese.ttf');
}

@font-face {
    font-family: 'Noto Serif Bengali';
    src: url('./assets/fonts/Noto Serif Bengali.ttf');
}

@font-face {
    font-family: 'Noto Serif Devanagari';
    src: url('./assets/fonts/Noto Serif Devanagari.ttf');
}

@font-face {
    font-family: 'Noto Serif Display';
    src: url('./assets/fonts/Noto Serif Display.ttf');
}

@font-face {
    font-family: 'Noto Serif Dogra';
    src: url('./assets/fonts/Noto Serif Dogra.ttf');
}

@font-face {
    font-family: 'Noto Serif Ethiopic';
    src: url('./assets/fonts/Noto Serif Ethiopic.ttf');
}

@font-face {
    font-family: 'Noto Serif Georgian';
    src: url('./assets/fonts/Noto Serif Georgian.ttf');
}

@font-face {
    font-family: 'Noto Serif Grantha';
    src: url('./assets/fonts/Noto Serif Grantha.ttf');
}

@font-face {
    font-family: 'Noto Serif Gujarati';
    src: url('./assets/fonts/Noto Serif Gujarati.ttf');
}

@font-face {
    font-family: 'Noto Serif Gurmukhi';
    src: url('./assets/fonts/Noto Serif Gurmukhi.ttf');
}

@font-face {
    font-family: 'Noto Serif Hebrew';
    src: url('./assets/fonts/Noto Serif Hebrew.ttf');
}

@font-face {
    font-family: 'Noto Serif HK';
    src: url('./assets/fonts/Noto Serif HK.ttf');
}

@font-face {
    font-family: 'Noto Serif JP';
    src: url('./assets/fonts/Noto Serif JP.ttf');
}

@font-face {
    font-family: 'Noto Serif Kannada';
    src: url('./assets/fonts/Noto Serif Kannada.ttf');
}

@font-face {
    font-family: 'Noto Serif Khitan Small Script';
    src: url('./assets/fonts/Noto Serif Khitan Small Script.ttf');
}

@font-face {
    font-family: 'Noto Serif Khmer';
    src: url('./assets/fonts/Noto Serif Khmer.ttf');
}

@font-face {
    font-family: 'Noto Serif Khojki';
    src: url('./assets/fonts/Noto Serif Khojki.ttf');
}

@font-face {
    font-family: 'Noto Serif KR';
    src: url('./assets/fonts/Noto Serif KR.ttf');
}

@font-face {
    font-family: 'Noto Serif Lao';
    src: url('./assets/fonts/Noto Serif Lao.ttf');
}

@font-face {
    font-family: 'Noto Serif Makasar';
    src: url('./assets/fonts/Noto Serif Makasar.ttf');
}

@font-face {
    font-family: 'Noto Serif Malayalam';
    src: url('./assets/fonts/Noto Serif Malayalam.ttf');
}

@font-face {
    font-family: 'Noto Serif Myanmar';
    src: url('./assets/fonts/Noto Serif Myanmar.ttf');
}

@font-face {
    font-family: 'Noto Serif NP Hmong';
    src: url('./assets/fonts/Noto Serif NP Hmong.ttf');
}

@font-face {
    font-family: 'Noto Serif Old Uyghur';
    src: url('./assets/fonts/Noto Serif Old Uyghur.ttf');
}

@font-face {
    font-family: 'Noto Serif Oriya';
    src: url('./assets/fonts/Noto Serif Oriya.ttf');
}

@font-face {
    font-family: 'Noto Serif Ottoman Siyaq';
    src: url('./assets/fonts/Noto Serif Ottoman Siyaq.ttf');
}

@font-face {
    font-family: 'Noto Serif SC';
    src: url('./assets/fonts/Noto Serif SC.ttf');
}

@font-face {
    font-family: 'Noto Serif Sinhala';
    src: url('./assets/fonts/Noto Serif Sinhala.ttf');
}

@font-face {
    font-family: 'Noto Serif Tamil';
    src: url('./assets/fonts/Noto Serif Tamil.ttf');
}

@font-face {
    font-family: 'Noto Serif Tangut';
    src: url('./assets/fonts/Noto Serif Tangut.ttf');
}

@font-face {
    font-family: 'Noto Serif TC';
    src: url('./assets/fonts/Noto Serif TC.ttf');
}

@font-face {
    font-family: 'Noto Serif Telugu';
    src: url('./assets/fonts/Noto Serif Telugu.ttf');
}

@font-face {
    font-family: 'Noto Serif Thai';
    src: url('./assets/fonts/Noto Serif Thai.ttf');
}

@font-face {
    font-family: 'Noto Serif Tibetan';
    src: url('./assets/fonts/Noto Serif Tibetan.ttf');
}

@font-face {
    font-family: 'Noto Serif Toto';
    src: url('./assets/fonts/Noto Serif Toto.ttf');
}

@font-face {
    font-family: 'Noto Serif Vithkuqi';
    src: url('./assets/fonts/Noto Serif Vithkuqi.ttf');
}

@font-face {
    font-family: 'Noto Serif Yezidi';
    src: url('./assets/fonts/Noto Serif Yezidi.ttf');
}

@font-face {
    font-family: 'Noto Serif';
    src: url('./assets/fonts/Noto Serif.ttf');
}

@font-face {
    font-family: 'Noto Traditional Nushu';
    src: url('./assets/fonts/Noto Traditional Nushu.ttf');
}

@font-face {
    font-family: 'Nova Cut';
    src: url('./assets/fonts/Nova Cut.ttf');
}

@font-face {
    font-family: 'Nova Flat';
    src: url('./assets/fonts/Nova Flat.ttf');
}

@font-face {
    font-family: 'Nova Mono';
    src: url('./assets/fonts/Nova Mono.ttf');
}

@font-face {
    font-family: 'Nova Oval';
    src: url('./assets/fonts/Nova Oval.ttf');
}

@font-face {
    font-family: 'Nova Round';
    src: url('./assets/fonts/Nova Round.ttf');
}

@font-face {
    font-family: 'Nova Script';
    src: url('./assets/fonts/Nova Script.ttf');
}

@font-face {
    font-family: 'Nova Slim';
    src: url('./assets/fonts/Nova Slim.ttf');
}

@font-face {
    font-family: 'Nova Square';
    src: url('./assets/fonts/Nova Square.ttf');
}

@font-face {
    font-family: 'NTR';
    src: url('./assets/fonts/NTR.ttf');
}

@font-face {
    font-family: 'Numans';
    src: url('./assets/fonts/Numans.ttf');
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./assets/fonts/Nunito Sans.ttf');
}

@font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/Nunito.ttf');
}

@font-face {
    font-family: 'Nuosu SIL';
    src: url('./assets/fonts/Nuosu SIL.ttf');
}

@font-face {
    font-family: 'Odibee Sans';
    src: url('./assets/fonts/Odibee Sans.ttf');
}

@font-face {
    font-family: 'Odor Mean Chey';
    src: url('./assets/fonts/Odor Mean Chey.ttf');
}

@font-face {
    font-family: 'Offside';
    src: url('./assets/fonts/Offside.ttf');
}

@font-face {
    font-family: 'Oi';
    src: url('./assets/fonts/Oi.ttf');
}

@font-face {
    font-family: 'Ojuju';
    src: url('./assets/fonts/Ojuju.ttf');
}

@font-face {
    font-family: 'Old Standard TT';
    src: url('./assets/fonts/Old Standard TT.ttf');
}

@font-face {
    font-family: 'Oldenburg';
    src: url('./assets/fonts/Oldenburg.ttf');
}

@font-face {
    font-family: 'Ole';
    src: url('./assets/fonts/Ole.ttf');
}

@font-face {
    font-family: 'Oleo Script Swash Caps';
    src: url('./assets/fonts/Oleo Script Swash Caps.ttf');
}

@font-face {
    font-family: 'Oleo Script';
    src: url('./assets/fonts/Oleo Script.ttf');
}

@font-face {
    font-family: 'Onest';
    src: url('./assets/fonts/Onest.ttf');
}

@font-face {
    font-family: 'Oooh Baby';
    src: url('./assets/fonts/Oooh Baby.ttf');
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/Open Sans.ttf');
}

@font-face {
    font-family: 'Oranienbaum';
    src: url('./assets/fonts/Oranienbaum.ttf');
}

@font-face {
    font-family: 'Orbit';
    src: url('./assets/fonts/Orbit.ttf');
}

@font-face {
    font-family: 'Orbitron';
    src: url('./assets/fonts/Orbitron.ttf');
}

@font-face {
    font-family: 'Oregano';
    src: url('./assets/fonts/Oregano.ttf');
}

@font-face {
    font-family: 'Orelega One';
    src: url('./assets/fonts/Orelega One.ttf');
}

@font-face {
    font-family: 'Orienta';
    src: url('./assets/fonts/Orienta.ttf');
}

@font-face {
    font-family: 'Original Surfer';
    src: url('./assets/fonts/Original Surfer.ttf');
}

@font-face {
    font-family: 'Oswald';
    src: url('./assets/fonts/Oswald.ttf');
}

@font-face {
    font-family: 'Outfit';
    src: url('./assets/fonts/Outfit.ttf');
}

@font-face {
    font-family: 'Over the Rainbow';
    src: url('./assets/fonts/Over the Rainbow.ttf');
}

@font-face {
    font-family: 'Overlock SC';
    src: url('./assets/fonts/Overlock SC.ttf');
}

@font-face {
    font-family: 'Overlock';
    src: url('./assets/fonts/Overlock.ttf');
}

@font-face {
    font-family: 'Overpass Mono';
    src: url('./assets/fonts/Overpass Mono.ttf');
}

@font-face {
    font-family: 'Overpass';
    src: url('./assets/fonts/Overpass.ttf');
}

@font-face {
    font-family: 'Ovo';
    src: url('./assets/fonts/Ovo.ttf');
}

@font-face {
    font-family: 'Oxanium';
    src: url('./assets/fonts/Oxanium.ttf');
}

@font-face {
    font-family: 'Oxygen Mono';
    src: url('./assets/fonts/Oxygen Mono.ttf');
}

@font-face {
    font-family: 'Oxygen';
    src: url('./assets/fonts/Oxygen.ttf');
}

@font-face {
    font-family: 'Pacifico';
    src: url('./assets/fonts/Pacifico.ttf');
}

@font-face {
    font-family: 'Padauk';
    src: url('./assets/fonts/Padauk.ttf');
}

@font-face {
    font-family: 'Padyakke Expanded One';
    src: url('./assets/fonts/Padyakke Expanded One.ttf');
}

@font-face {
    font-family: 'Palanquin Dark';
    src: url('./assets/fonts/Palanquin Dark.ttf');
}

@font-face {
    font-family: 'Palanquin';
    src: url('./assets/fonts/Palanquin.ttf');
}

@font-face {
    font-family: 'Palette Mosaic';
    src: url('./assets/fonts/Palette Mosaic.ttf');
}

@font-face {
    font-family: 'Pangolin';
    src: url('./assets/fonts/Pangolin.ttf');
}

@font-face {
    font-family: 'Paprika';
    src: url('./assets/fonts/Paprika.ttf');
}

@font-face {
    font-family: 'Parisienne';
    src: url('./assets/fonts/Parisienne.ttf');
}

@font-face {
    font-family: 'Passero One';
    src: url('./assets/fonts/Passero One.ttf');
}

@font-face {
    font-family: 'Passion One';
    src: url('./assets/fonts/Passion One.ttf');
}

@font-face {
    font-family: 'Passions Conflict';
    src: url('./assets/fonts/Passions Conflict.ttf');
}

@font-face {
    font-family: 'Pathway Extreme';
    src: url('./assets/fonts/Pathway Extreme.ttf');
}

@font-face {
    font-family: 'Pathway Gothic One';
    src: url('./assets/fonts/Pathway Gothic One.ttf');
}

@font-face {
    font-family: 'Patrick Hand SC';
    src: url('./assets/fonts/Patrick Hand SC.ttf');
}

@font-face {
    font-family: 'Patrick Hand';
    src: url('./assets/fonts/Patrick Hand.ttf');
}

@font-face {
    font-family: 'Pattaya';
    src: url('./assets/fonts/Pattaya.ttf');
}

@font-face {
    font-family: 'Patua One';
    src: url('./assets/fonts/Patua One.ttf');
}

@font-face {
    font-family: 'Pavanam';
    src: url('./assets/fonts/Pavanam.ttf');
}

@font-face {
    font-family: 'Paytone One';
    src: url('./assets/fonts/Paytone One.ttf');
}

@font-face {
    font-family: 'Peddana';
    src: url('./assets/fonts/Peddana.ttf');
}

@font-face {
    font-family: 'Peralta';
    src: url('./assets/fonts/Peralta.ttf');
}

@font-face {
    font-family: 'Permanent Marker';
    src: url('./assets/fonts/Permanent Marker.ttf');
}

@font-face {
    font-family: 'Petemoss';
    src: url('./assets/fonts/Petemoss.ttf');
}

@font-face {
    font-family: 'Petit Formal Script';
    src: url('./assets/fonts/Petit Formal Script.ttf');
}

@font-face {
    font-family: 'Petrona';
    src: url('./assets/fonts/Petrona.ttf');
}

@font-face {
    font-family: 'Philosopher';
    src: url('./assets/fonts/Philosopher.ttf');
}

@font-face {
    font-family: 'Phudu';
    src: url('./assets/fonts/Phudu.ttf');
}

@font-face {
    font-family: 'Piazzolla';
    src: url('./assets/fonts/Piazzolla.ttf');
}

@font-face {
    font-family: 'Piedra';
    src: url('./assets/fonts/Piedra.ttf');
}

@font-face {
    font-family: 'Pinyon Script';
    src: url('./assets/fonts/Pinyon Script.ttf');
}

@font-face {
    font-family: 'Pirata One';
    src: url('./assets/fonts/Pirata One.ttf');
}

@font-face {
    font-family: 'Pixelify Sans';
    src: url('./assets/fonts/Pixelify Sans.ttf');
}

@font-face {
    font-family: 'Plaster';
    src: url('./assets/fonts/Plaster.ttf');
}

@font-face {
    font-family: 'Play';
    src: url('./assets/fonts/Play.ttf');
}

@font-face {
    font-family: 'Playball';
    src: url('./assets/fonts/Playball.ttf');
}

@font-face {
    font-family: 'Playfair Display SC';
    src: url('./assets/fonts/Playfair Display SC.ttf');
}

@font-face {
    font-family: 'Playfair Display';
    src: url('./assets/fonts/Playfair Display.ttf');
}

@font-face {
    font-family: 'Playfair';
    src: url('./assets/fonts/Playfair.ttf');
}

@font-face {
    font-family: 'Playpen Sans';
    src: url('./assets/fonts/Playpen Sans.ttf');
}

@font-face {
    font-family: 'Plus Jakarta Sans';
    src: url('./assets/fonts/Plus Jakarta Sans.ttf');
}

@font-face {
    font-family: 'Podkova';
    src: url('./assets/fonts/Podkova.ttf');
}

@font-face {
    font-family: 'Poiret One';
    src: url('./assets/fonts/Poiret One.ttf');
}

@font-face {
    font-family: 'Poller One';
    src: url('./assets/fonts/Poller One.ttf');
}

@font-face {
    font-family: 'Poltawski Nowy';
    src: url('./assets/fonts/Poltawski Nowy.ttf');
}

@font-face {
    font-family: 'Poly';
    src: url('./assets/fonts/Poly.ttf');
}

@font-face {
    font-family: 'Pompiere';
    src: url('./assets/fonts/Pompiere.ttf');
}

@font-face {
    font-family: 'Pontano Sans';
    src: url('./assets/fonts/Pontano Sans.ttf');
}

@font-face {
    font-family: 'Poor Story';
    src: url('./assets/fonts/Poor Story.ttf');
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins.ttf');
}

@font-face {
    font-family: 'Port Lligat Sans';
    src: url('./assets/fonts/Port Lligat Sans.ttf');
}

@font-face {
    font-family: 'Port Lligat Slab';
    src: url('./assets/fonts/Port Lligat Slab.ttf');
}

@font-face {
    font-family: 'Potta One';
    src: url('./assets/fonts/Potta One.ttf');
}

@font-face {
    font-family: 'Pragati Narrow';
    src: url('./assets/fonts/Pragati Narrow.ttf');
}

@font-face {
    font-family: 'Praise';
    src: url('./assets/fonts/Praise.ttf');
}

@font-face {
    font-family: 'Prata';
    src: url('./assets/fonts/Prata.ttf');
}

@font-face {
    font-family: 'Preahvihear';
    src: url('./assets/fonts/Preahvihear.ttf');
}

@font-face {
    font-family: 'Press Start 2P';
    src: url('./assets/fonts/Press Start 2P.ttf');
}

@font-face {
    font-family: 'Pridi';
    src: url('./assets/fonts/Pridi.ttf');
}

@font-face {
    font-family: 'Princess Sofia';
    src: url('./assets/fonts/Princess Sofia.ttf');
}

@font-face {
    font-family: 'Prociono';
    src: url('./assets/fonts/Prociono.ttf');
}

@font-face {
    font-family: 'Prompt';
    src: url('./assets/fonts/Prompt.ttf');
}

@font-face {
    font-family: 'Prosto One';
    src: url('./assets/fonts/Prosto One.ttf');
}

@font-face {
    font-family: 'Protest Guerrilla';
    src: url('./assets/fonts/Protest Guerrilla.ttf');
}

@font-face {
    font-family: 'Protest Revolution';
    src: url('./assets/fonts/Protest Revolution.ttf');
}

@font-face {
    font-family: 'Protest Riot';
    src: url('./assets/fonts/Protest Riot.ttf');
}

@font-face {
    font-family: 'Protest Strike';
    src: url('./assets/fonts/Protest Strike.ttf');
}

@font-face {
    font-family: 'Proza Libre';
    src: url('./assets/fonts/Proza Libre.ttf');
}

@font-face {
    font-family: 'PT Mono';
    src: url('./assets/fonts/PT Mono.ttf');
}

@font-face {
    font-family: 'PT Sans Caption';
    src: url('./assets/fonts/PT Sans Caption.ttf');
}

@font-face {
    font-family: 'PT Sans Narrow';
    src: url('./assets/fonts/PT Sans Narrow.ttf');
}

@font-face {
    font-family: 'PT Sans';
    src: url('./assets/fonts/PT Sans.ttf');
}

@font-face {
    font-family: 'PT Serif Caption';
    src: url('./assets/fonts/PT Serif Caption.ttf');
}

@font-face {
    font-family: 'PT Serif';
    src: url('./assets/fonts/PT Serif.ttf');
}

@font-face {
    font-family: 'Public Sans';
    src: url('./assets/fonts/Public Sans.ttf');
}

@font-face {
    font-family: 'Puppies Play';
    src: url('./assets/fonts/Puppies Play.ttf');
}

@font-face {
    font-family: 'Puritan';
    src: url('./assets/fonts/Puritan.ttf');
}

@font-face {
    font-family: 'Purple Purse';
    src: url('./assets/fonts/Purple Purse.ttf');
}

@font-face {
    font-family: 'Qahiri';
    src: url('./assets/fonts/Qahiri.ttf');
}

@font-face {
    font-family: 'Quando';
    src: url('./assets/fonts/Quando.ttf');
}

@font-face {
    font-family: 'Quantico';
    src: url('./assets/fonts/Quantico.ttf');
}

@font-face {
    font-family: 'Quattrocento Sans';
    src: url('./assets/fonts/Quattrocento Sans.ttf');
}

@font-face {
    font-family: 'Quattrocento';
    src: url('./assets/fonts/Quattrocento.ttf');
}

@font-face {
    font-family: 'Questrial';
    src: url('./assets/fonts/Questrial.ttf');
}

@font-face {
    font-family: 'Quicksand';
    src: url('./assets/fonts/Quicksand.ttf');
}

@font-face {
    font-family: 'Quintessential';
    src: url('./assets/fonts/Quintessential.ttf');
}

@font-face {
    font-family: 'Qwigley';
    src: url('./assets/fonts/Qwigley.ttf');
}

@font-face {
    font-family: 'Qwitcher Grypen';
    src: url('./assets/fonts/Qwitcher Grypen.ttf');
}

@font-face {
    font-family: 'Racing Sans One';
    src: url('./assets/fonts/Racing Sans One.ttf');
}

@font-face {
    font-family: 'Radio Canada';
    src: url('./assets/fonts/Radio Canada.ttf');
}

@font-face {
    font-family: 'Radley';
    src: url('./assets/fonts/Radley.ttf');
}

@font-face {
    font-family: 'Rajdhani';
    src: url('./assets/fonts/Rajdhani.ttf');
}

@font-face {
    font-family: 'Rakkas';
    src: url('./assets/fonts/Rakkas.ttf');
}

@font-face {
    font-family: 'Raleway Dots';
    src: url('./assets/fonts/Raleway Dots.ttf');
}

@font-face {
    font-family: 'Raleway';
    src: url('./assets/fonts/Raleway.ttf');
}

@font-face {
    font-family: 'Ramabhadra';
    src: url('./assets/fonts/Ramabhadra.ttf');
}

@font-face {
    font-family: 'Ramaraja';
    src: url('./assets/fonts/Ramaraja.ttf');
}

@font-face {
    font-family: 'Rambla';
    src: url('./assets/fonts/Rambla.ttf');
}

@font-face {
    font-family: 'Rammetto One';
    src: url('./assets/fonts/Rammetto One.ttf');
}

@font-face {
    font-family: 'Rampart One';
    src: url('./assets/fonts/Rampart One.ttf');
}

@font-face {
    font-family: 'Ranchers';
    src: url('./assets/fonts/Ranchers.ttf');
}

@font-face {
    font-family: 'Rancho';
    src: url('./assets/fonts/Rancho.ttf');
}

@font-face {
    font-family: 'Ranga';
    src: url('./assets/fonts/Ranga.ttf');
}

@font-face {
    font-family: 'Rasa';
    src: url('./assets/fonts/Rasa.ttf');
}

@font-face {
    font-family: 'Rationale';
    src: url('./assets/fonts/Rationale.ttf');
}

@font-face {
    font-family: 'Ravi Prakash';
    src: url('./assets/fonts/Ravi Prakash.ttf');
}

@font-face {
    font-family: 'Readex Pro';
    src: url('./assets/fonts/Readex Pro.ttf');
}

@font-face {
    font-family: 'Recursive';
    src: url('./assets/fonts/Recursive.ttf');
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./assets/fonts/Red Hat Display.ttf');
}

@font-face {
    font-family: 'Red Hat Mono';
    src: url('./assets/fonts/Red Hat Mono.ttf');
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('./assets/fonts/Red Hat Text.ttf');
}

@font-face {
    font-family: 'Red Rose';
    src: url('./assets/fonts/Red Rose.ttf');
}

@font-face {
    font-family: 'Redacted Script';
    src: url('./assets/fonts/Redacted Script.ttf');
}

@font-face {
    font-family: 'Redacted';
    src: url('./assets/fonts/Redacted.ttf');
}

@font-face {
    font-family: 'Reddit Mono';
    src: url('./assets/fonts/Reddit Mono.ttf');
}

@font-face {
    font-family: 'Redressed';
    src: url('./assets/fonts/Redressed.ttf');
}

@font-face {
    font-family: 'Reem Kufi Fun';
    src: url('./assets/fonts/Reem Kufi Fun.ttf');
}

@font-face {
    font-family: 'Reem Kufi Ink';
    src: url('./assets/fonts/Reem Kufi Ink.ttf');
}

@font-face {
    font-family: 'Reem Kufi';
    src: url('./assets/fonts/Reem Kufi.ttf');
}

@font-face {
    font-family: 'Reenie Beanie';
    src: url('./assets/fonts/Reenie Beanie.ttf');
}

@font-face {
    font-family: 'Reggae One';
    src: url('./assets/fonts/Reggae One.ttf');
}

@font-face {
    font-family: 'REM';
    src: url('./assets/fonts/REM.ttf');
}

@font-face {
    font-family: 'Rethink Sans';
    src: url('./assets/fonts/Rethink Sans.ttf');
}

@font-face {
    font-family: 'Revalia';
    src: url('./assets/fonts/Revalia.ttf');
}

@font-face {
    font-family: 'Rhodium Libre';
    src: url('./assets/fonts/Rhodium Libre.ttf');
}

@font-face {
    font-family: 'Ribeye Marrow';
    src: url('./assets/fonts/Ribeye Marrow.ttf');
}

@font-face {
    font-family: 'Ribeye';
    src: url('./assets/fonts/Ribeye.ttf');
}

@font-face {
    font-family: 'Righteous';
    src: url('./assets/fonts/Righteous.ttf');
}

@font-face {
    font-family: 'Risque';
    src: url('./assets/fonts/Risque.ttf');
}

@font-face {
    font-family: 'Road Rage';
    src: url('./assets/fonts/Road Rage.ttf');
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('./assets/fonts/Roboto Condensed.ttf');
}

@font-face {
    font-family: 'Roboto Flex';
    src: url('./assets/fonts/Roboto Flex.ttf');
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('./assets/fonts/Roboto Mono.ttf');
}

@font-face {
    font-family: 'Roboto Serif';
    src: url('./assets/fonts/Roboto Serif.ttf');
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('./assets/fonts/Roboto Slab.ttf');
}

@font-face {
    font-family: 'Roboto';
    src: url('./assets/fonts/Roboto.ttf');
}

@font-face {
    font-family: 'Rochester';
    src: url('./assets/fonts/Rochester.ttf');
}

@font-face {
    font-family: 'Rock 3D';
    src: url('./assets/fonts/Rock 3D.ttf');
}

@font-face {
    font-family: 'Rock Salt';
    src: url('./assets/fonts/Rock Salt.ttf');
}

@font-face {
    font-family: 'RocknRoll One';
    src: url('./assets/fonts/RocknRoll One.ttf');
}

@font-face {
    font-family: 'Rokkitt';
    src: url('./assets/fonts/Rokkitt.ttf');
}

@font-face {
    font-family: 'Romanesco';
    src: url('./assets/fonts/Romanesco.ttf');
}

@font-face {
    font-family: 'Ropa Sans';
    src: url('./assets/fonts/Ropa Sans.ttf');
}

@font-face {
    font-family: 'Rosario';
    src: url('./assets/fonts/Rosario.ttf');
}

@font-face {
    font-family: 'Rosarivo';
    src: url('./assets/fonts/Rosarivo.ttf');
}

@font-face {
    font-family: 'Rouge Script';
    src: url('./assets/fonts/Rouge Script.ttf');
}

@font-face {
    font-family: 'Rowdies';
    src: url('./assets/fonts/Rowdies.ttf');
}

@font-face {
    font-family: 'Rozha One';
    src: url('./assets/fonts/Rozha One.ttf');
}

@font-face {
    font-family: 'Rubik 80s Fade';
    src: url('./assets/fonts/Rubik 80s Fade.ttf');
}

@font-face {
    font-family: 'Rubik Beastly';
    src: url('./assets/fonts/Rubik Beastly.ttf');
}

@font-face {
    font-family: 'Rubik Broken Fax';
    src: url('./assets/fonts/Rubik Broken Fax.ttf');
}

@font-face {
    font-family: 'Rubik Bubbles';
    src: url('./assets/fonts/Rubik Bubbles.ttf');
}

@font-face {
    font-family: 'Rubik Burned';
    src: url('./assets/fonts/Rubik Burned.ttf');
}

@font-face {
    font-family: 'Rubik Dirt';
    src: url('./assets/fonts/Rubik Dirt.ttf');
}

@font-face {
    font-family: 'Rubik Distressed';
    src: url('./assets/fonts/Rubik Distressed.ttf');
}

@font-face {
    font-family: 'Rubik Doodle Shadow';
    src: url('./assets/fonts/Rubik Doodle Shadow.ttf');
}

@font-face {
    font-family: 'Rubik Doodle Triangles';
    src: url('./assets/fonts/Rubik Doodle Triangles.ttf');
}

@font-face {
    font-family: 'Rubik Gemstones';
    src: url('./assets/fonts/Rubik Gemstones.ttf');
}

@font-face {
    font-family: 'Rubik Glitch Pop';
    src: url('./assets/fonts/Rubik Glitch Pop.ttf');
}

@font-face {
    font-family: 'Rubik Glitch';
    src: url('./assets/fonts/Rubik Glitch.ttf');
}

@font-face {
    font-family: 'Rubik Iso';
    src: url('./assets/fonts/Rubik Iso.ttf');
}

@font-face {
    font-family: 'Rubik Lines';
    src: url('./assets/fonts/Rubik Lines.ttf');
}

@font-face {
    font-family: 'Rubik Maps';
    src: url('./assets/fonts/Rubik Maps.ttf');
}

@font-face {
    font-family: 'Rubik Marker Hatch';
    src: url('./assets/fonts/Rubik Marker Hatch.ttf');
}

@font-face {
    font-family: 'Rubik Maze';
    src: url('./assets/fonts/Rubik Maze.ttf');
}

@font-face {
    font-family: 'Rubik Microbe';
    src: url('./assets/fonts/Rubik Microbe.ttf');
}

@font-face {
    font-family: 'Rubik Mono One';
    src: url('./assets/fonts/Rubik Mono One.ttf');
}

@font-face {
    font-family: 'Rubik Moonrocks';
    src: url('./assets/fonts/Rubik Moonrocks.ttf');
}

@font-face {
    font-family: 'Rubik Pixels';
    src: url('./assets/fonts/Rubik Pixels.ttf');
}

@font-face {
    font-family: 'Rubik Puddles';
    src: url('./assets/fonts/Rubik Puddles.ttf');
}

@font-face {
    font-family: 'Rubik Scribble';
    src: url('./assets/fonts/Rubik Scribble.ttf');
}

@font-face {
    font-family: 'Rubik Spray Paint';
    src: url('./assets/fonts/Rubik Spray Paint.ttf');
}

@font-face {
    font-family: 'Rubik Storm';
    src: url('./assets/fonts/Rubik Storm.ttf');
}

@font-face {
    font-family: 'Rubik Vinyl';
    src: url('./assets/fonts/Rubik Vinyl.ttf');
}

@font-face {
    font-family: 'Rubik Wet Paint';
    src: url('./assets/fonts/Rubik Wet Paint.ttf');
}

@font-face {
    font-family: 'Rubik';
    src: url('./assets/fonts/Rubik.ttf');
}

@font-face {
    font-family: 'Ruda';
    src: url('./assets/fonts/Ruda.ttf');
}

@font-face {
    font-family: 'Rufina';
    src: url('./assets/fonts/Rufina.ttf');
}

@font-face {
    font-family: 'Ruge Boogie';
    src: url('./assets/fonts/Ruge Boogie.ttf');
}

@font-face {
    font-family: 'Ruluko';
    src: url('./assets/fonts/Ruluko.ttf');
}

@font-face {
    font-family: 'Rum Raisin';
    src: url('./assets/fonts/Rum Raisin.ttf');
}

@font-face {
    font-family: 'Ruslan Display';
    src: url('./assets/fonts/Ruslan Display.ttf');
}

@font-face {
    font-family: 'Russo One';
    src: url('./assets/fonts/Russo One.ttf');
}

@font-face {
    font-family: 'Ruthie';
    src: url('./assets/fonts/Ruthie.ttf');
}

@font-face {
    font-family: 'Ruwudu';
    src: url('./assets/fonts/Ruwudu.ttf');
}

@font-face {
    font-family: 'Rye';
    src: url('./assets/fonts/Rye.ttf');
}

@font-face {
    font-family: 'Sacramento';
    src: url('./assets/fonts/Sacramento.ttf');
}

@font-face {
    font-family: 'Sahitya';
    src: url('./assets/fonts/Sahitya.ttf');
}

@font-face {
    font-family: 'Sail';
    src: url('./assets/fonts/Sail.ttf');
}

@font-face {
    font-family: 'Saira Condensed';
    src: url('./assets/fonts/Saira Condensed.ttf');
}

@font-face {
    font-family: 'Saira Extra Condensed';
    src: url('./assets/fonts/Saira Extra Condensed.ttf');
}

@font-face {
    font-family: 'Saira Semi Condensed';
    src: url('./assets/fonts/Saira Semi Condensed.ttf');
}

@font-face {
    font-family: 'Saira Stencil One';
    src: url('./assets/fonts/Saira Stencil One.ttf');
}

@font-face {
    font-family: 'Saira';
    src: url('./assets/fonts/Saira.ttf');
}

@font-face {
    font-family: 'Salsa';
    src: url('./assets/fonts/Salsa.ttf');
}

@font-face {
    font-family: 'Sanchez';
    src: url('./assets/fonts/Sanchez.ttf');
}

@font-face {
    font-family: 'Sancreek';
    src: url('./assets/fonts/Sancreek.ttf');
}

@font-face {
    font-family: 'Sansita Swashed';
    src: url('./assets/fonts/Sansita Swashed.ttf');
}

@font-face {
    font-family: 'Sansita';
    src: url('./assets/fonts/Sansita.ttf');
}

@font-face {
    font-family: 'Sarabun';
    src: url('./assets/fonts/Sarabun.ttf');
}

@font-face {
    font-family: 'Sarala';
    src: url('./assets/fonts/Sarala.ttf');
}

@font-face {
    font-family: 'Sarina';
    src: url('./assets/fonts/Sarina.ttf');
}

@font-face {
    font-family: 'Sarpanch';
    src: url('./assets/fonts/Sarpanch.ttf');
}

@font-face {
    font-family: 'Sassy Frass';
    src: url('./assets/fonts/Sassy Frass.ttf');
}

@font-face {
    font-family: 'Satisfy';
    src: url('./assets/fonts/Satisfy.ttf');
}

@font-face {
    font-family: 'Sawarabi Gothic';
    src: url('./assets/fonts/Sawarabi Gothic.ttf');
}

@font-face {
    font-family: 'Sawarabi Mincho';
    src: url('./assets/fonts/Sawarabi Mincho.ttf');
}

@font-face {
    font-family: 'Scada';
    src: url('./assets/fonts/Scada.ttf');
}

@font-face {
    font-family: 'Scheherazade New';
    src: url('./assets/fonts/Scheherazade New.ttf');
}

@font-face {
    font-family: 'Schibsted Grotesk';
    src: url('./assets/fonts/Schibsted Grotesk.ttf');
}

@font-face {
    font-family: 'Schoolbell';
    src: url('./assets/fonts/Schoolbell.ttf');
}

@font-face {
    font-family: 'Scope One';
    src: url('./assets/fonts/Scope One.ttf');
}

@font-face {
    font-family: 'Seaweed Script';
    src: url('./assets/fonts/Seaweed Script.ttf');
}

@font-face {
    font-family: 'Secular One';
    src: url('./assets/fonts/Secular One.ttf');
}

@font-face {
    font-family: 'Sedgwick Ave Display';
    src: url('./assets/fonts/Sedgwick Ave Display.ttf');
}

@font-face {
    font-family: 'Sedgwick Ave';
    src: url('./assets/fonts/Sedgwick Ave.ttf');
}

@font-face {
    font-family: 'Sen';
    src: url('./assets/fonts/Sen.ttf');
}

@font-face {
    font-family: 'Send Flowers';
    src: url('./assets/fonts/Send Flowers.ttf');
}

@font-face {
    font-family: 'Sevillana';
    src: url('./assets/fonts/Sevillana.ttf');
}

@font-face {
    font-family: 'Seymour One';
    src: url('./assets/fonts/Seymour One.ttf');
}

@font-face {
    font-family: 'Shadows Into Light Two';
    src: url('./assets/fonts/Shadows Into Light Two.ttf');
}

@font-face {
    font-family: 'Shadows Into Light';
    src: url('./assets/fonts/Shadows Into Light.ttf');
}

@font-face {
    font-family: 'Shalimar';
    src: url('./assets/fonts/Shalimar.ttf');
}

@font-face {
    font-family: 'Shantell Sans';
    src: url('./assets/fonts/Shantell Sans.ttf');
}

@font-face {
    font-family: 'Shanti';
    src: url('./assets/fonts/Shanti.ttf');
}

@font-face {
    font-family: 'Share Tech Mono';
    src: url('./assets/fonts/Share Tech Mono.ttf');
}

@font-face {
    font-family: 'Share Tech';
    src: url('./assets/fonts/Share Tech.ttf');
}

@font-face {
    font-family: 'Share';
    src: url('./assets/fonts/Share.ttf');
}

@font-face {
    font-family: 'Shippori Antique B1';
    src: url('./assets/fonts/Shippori Antique B1.ttf');
}

@font-face {
    font-family: 'Shippori Antique';
    src: url('./assets/fonts/Shippori Antique.ttf');
}

@font-face {
    font-family: 'Shippori Mincho B1';
    src: url('./assets/fonts/Shippori Mincho B1.ttf');
}

@font-face {
    font-family: 'Shippori Mincho';
    src: url('./assets/fonts/Shippori Mincho.ttf');
}

@font-face {
    font-family: 'Shizuru';
    src: url('./assets/fonts/Shizuru.ttf');
}

@font-face {
    font-family: 'Shojumaru';
    src: url('./assets/fonts/Shojumaru.ttf');
}

@font-face {
    font-family: 'Short Stack';
    src: url('./assets/fonts/Short Stack.ttf');
}

@font-face {
    font-family: 'Shrikhand';
    src: url('./assets/fonts/Shrikhand.ttf');
}

@font-face {
    font-family: 'Siemreap';
    src: url('./assets/fonts/Siemreap.ttf');
}

@font-face {
    font-family: 'Sigmar One';
    src: url('./assets/fonts/Sigmar One.ttf');
}

@font-face {
    font-family: 'Sigmar';
    src: url('./assets/fonts/Sigmar.ttf');
}

@font-face {
    font-family: 'Signika Negative';
    src: url('./assets/fonts/Signika Negative.ttf');
}

@font-face {
    font-family: 'Signika';
    src: url('./assets/fonts/Signika.ttf');
}

@font-face {
    font-family: 'Silkscreen';
    src: url('./assets/fonts/Silkscreen.ttf');
}

@font-face {
    font-family: 'Simonetta';
    src: url('./assets/fonts/Simonetta.ttf');
}

@font-face {
    font-family: 'Single Day';
    src: url('./assets/fonts/Single Day.ttf');
}

@font-face {
    font-family: 'Sintony';
    src: url('./assets/fonts/Sintony.ttf');
}

@font-face {
    font-family: 'Sirin Stencil';
    src: url('./assets/fonts/Sirin Stencil.ttf');
}

@font-face {
    font-family: 'Six Caps';
    src: url('./assets/fonts/Six Caps.ttf');
}

@font-face {
    font-family: 'Sixtyfour';
    src: url('./assets/fonts/Sixtyfour.ttf');
}

@font-face {
    font-family: 'Skranji';
    src: url('./assets/fonts/Skranji.ttf');
}

@font-face {
    font-family: 'Slabo 13px';
    src: url('./assets/fonts/Slabo 13px.ttf');
}

@font-face {
    font-family: 'Slabo 27px';
    src: url('./assets/fonts/Slabo 27px.ttf');
}

@font-face {
    font-family: 'Slackey';
    src: url('./assets/fonts/Slackey.ttf');
}

@font-face {
    font-family: 'Slackside One';
    src: url('./assets/fonts/Slackside One.ttf');
}

@font-face {
    font-family: 'Smokum';
    src: url('./assets/fonts/Smokum.ttf');
}

@font-face {
    font-family: 'Smooch Sans';
    src: url('./assets/fonts/Smooch Sans.ttf');
}

@font-face {
    font-family: 'Smooch';
    src: url('./assets/fonts/Smooch.ttf');
}

@font-face {
    font-family: 'Smythe';
    src: url('./assets/fonts/Smythe.ttf');
}

@font-face {
    font-family: 'Sniglet';
    src: url('./assets/fonts/Sniglet.ttf');
}

@font-face {
    font-family: 'Snippet';
    src: url('./assets/fonts/Snippet.ttf');
}

@font-face {
    font-family: 'Snowburst One';
    src: url('./assets/fonts/Snowburst One.ttf');
}

@font-face {
    font-family: 'Sofadi One';
    src: url('./assets/fonts/Sofadi One.ttf');
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('./assets/fonts/Sofia Sans Condensed.ttf');
}

@font-face {
    font-family: 'Sofia Sans Extra Condensed';
    src: url('./assets/fonts/Sofia Sans Extra Condensed.ttf');
}

@font-face {
    font-family: 'Sofia Sans Semi Condensed';
    src: url('./assets/fonts/Sofia Sans Semi Condensed.ttf');
}

@font-face {
    font-family: 'Sofia Sans';
    src: url('./assets/fonts/Sofia Sans.ttf');
}

@font-face {
    font-family: 'Sofia';
    src: url('./assets/fonts/Sofia.ttf');
}

@font-face {
    font-family: 'Solitreo';
    src: url('./assets/fonts/Solitreo.ttf');
}

@font-face {
    font-family: 'Solway';
    src: url('./assets/fonts/Solway.ttf');
}

@font-face {
    font-family: 'Sometype Mono';
    src: url('./assets/fonts/Sometype Mono.ttf');
}

@font-face {
    font-family: 'Song Myung';
    src: url('./assets/fonts/Song Myung.ttf');
}

@font-face {
    font-family: 'Sono';
    src: url('./assets/fonts/Sono.ttf');
}

@font-face {
    font-family: 'Sonsie One';
    src: url('./assets/fonts/Sonsie One.ttf');
}

@font-face {
    font-family: 'Sora';
    src: url('./assets/fonts/Sora.ttf');
}

@font-face {
    font-family: 'Sorts Mill Goudy';
    src: url('./assets/fonts/Sorts Mill Goudy.ttf');
}

@font-face {
    font-family: 'Source Code Pro';
    src: url('./assets/fonts/Source Code Pro.ttf');
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('./assets/fonts/Source Sans 3.ttf');
}

@font-face {
    font-family: 'Source Serif 4';
    src: url('./assets/fonts/Source Serif 4.ttf');
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('./assets/fonts/Space Grotesk.ttf');
}

@font-face {
    font-family: 'Space Mono';
    src: url('./assets/fonts/Space Mono.ttf');
}

@font-face {
    font-family: 'Special Elite';
    src: url('./assets/fonts/Special Elite.ttf');
}

@font-face {
    font-family: 'Spectral SC';
    src: url('./assets/fonts/Spectral SC.ttf');
}

@font-face {
    font-family: 'Spectral';
    src: url('./assets/fonts/Spectral.ttf');
}

@font-face {
    font-family: 'Spicy Rice';
    src: url('./assets/fonts/Spicy Rice.ttf');
}

@font-face {
    font-family: 'Spinnaker';
    src: url('./assets/fonts/Spinnaker.ttf');
}

@font-face {
    font-family: 'Spirax';
    src: url('./assets/fonts/Spirax.ttf');
}

@font-face {
    font-family: 'Splash';
    src: url('./assets/fonts/Splash.ttf');
}

@font-face {
    font-family: 'Spline Sans Mono';
    src: url('./assets/fonts/Spline Sans Mono.ttf');
}

@font-face {
    font-family: 'Spline Sans';
    src: url('./assets/fonts/Spline Sans.ttf');
}

@font-face {
    font-family: 'Squada One';
    src: url('./assets/fonts/Squada One.ttf');
}

@font-face {
    font-family: 'Square Peg';
    src: url('./assets/fonts/Square Peg.ttf');
}

@font-face {
    font-family: 'Sree Krushnadevaraya';
    src: url('./assets/fonts/Sree Krushnadevaraya.ttf');
}

@font-face {
    font-family: 'Sriracha';
    src: url('./assets/fonts/Sriracha.ttf');
}

@font-face {
    font-family: 'Srisakdi';
    src: url('./assets/fonts/Srisakdi.ttf');
}

@font-face {
    font-family: 'Staatliches';
    src: url('./assets/fonts/Staatliches.ttf');
}

@font-face {
    font-family: 'Stalemate';
    src: url('./assets/fonts/Stalemate.ttf');
}

@font-face {
    font-family: 'Stalinist One';
    src: url('./assets/fonts/Stalinist One.ttf');
}

@font-face {
    font-family: 'Stardos Stencil';
    src: url('./assets/fonts/Stardos Stencil.ttf');
}

@font-face {
    font-family: 'Stick No Bills';
    src: url('./assets/fonts/Stick No Bills.ttf');
}

@font-face {
    font-family: 'Stick';
    src: url('./assets/fonts/Stick.ttf');
}

@font-face {
    font-family: 'Stint Ultra Condensed';
    src: url('./assets/fonts/Stint Ultra Condensed.ttf');
}

@font-face {
    font-family: 'Stint Ultra Expanded';
    src: url('./assets/fonts/Stint Ultra Expanded.ttf');
}

@font-face {
    font-family: 'STIX Two Text';
    src: url('./assets/fonts/STIX Two Text.ttf');
}

@font-face {
    font-family: 'Stoke';
    src: url('./assets/fonts/Stoke.ttf');
}

@font-face {
    font-family: 'Strait';
    src: url('./assets/fonts/Strait.ttf');
}

@font-face {
    font-family: 'Style Script';
    src: url('./assets/fonts/Style Script.ttf');
}

@font-face {
    font-family: 'Stylish';
    src: url('./assets/fonts/Stylish.ttf');
}

@font-face {
    font-family: 'Sue Ellen Francisco';
    src: url('./assets/fonts/Sue Ellen Francisco.ttf');
}

@font-face {
    font-family: 'Suez One';
    src: url('./assets/fonts/Suez One.ttf');
}

@font-face {
    font-family: 'Sulphur Point';
    src: url('./assets/fonts/Sulphur Point.ttf');
}

@font-face {
    font-family: 'Sumana';
    src: url('./assets/fonts/Sumana.ttf');
}

@font-face {
    font-family: 'Sunflower';
    src: url('./assets/fonts/Sunflower.ttf');
}

@font-face {
    font-family: 'Sunshiney';
    src: url('./assets/fonts/Sunshiney.ttf');
}

@font-face {
    font-family: 'Supermercado One';
    src: url('./assets/fonts/Supermercado One.ttf');
}

@font-face {
    font-family: 'Sura';
    src: url('./assets/fonts/Sura.ttf');
}

@font-face {
    font-family: 'Suranna';
    src: url('./assets/fonts/Suranna.ttf');
}

@font-face {
    font-family: 'Suravaram';
    src: url('./assets/fonts/Suravaram.ttf');
}

@font-face {
    font-family: 'Suwannaphum';
    src: url('./assets/fonts/Suwannaphum.ttf');
}

@font-face {
    font-family: 'Swanky and Moo Moo';
    src: url('./assets/fonts/Swanky and Moo Moo.ttf');
}

@font-face {
    font-family: 'Syncopate';
    src: url('./assets/fonts/Syncopate.ttf');
}

@font-face {
    font-family: 'Syne Mono';
    src: url('./assets/fonts/Syne Mono.ttf');
}

@font-face {
    font-family: 'Syne Tactile';
    src: url('./assets/fonts/Syne Tactile.ttf');
}

@font-face {
    font-family: 'Syne';
    src: url('./assets/fonts/Syne.ttf');
}

@font-face {
    font-family: 'Tac One';
    src: url('./assets/fonts/Tac One.ttf');
}

@font-face {
    font-family: 'Tai Heritage Pro';
    src: url('./assets/fonts/Tai Heritage Pro.ttf');
}

@font-face {
    font-family: 'Tajawal';
    src: url('./assets/fonts/Tajawal.ttf');
}

@font-face {
    font-family: 'Tangerine';
    src: url('./assets/fonts/Tangerine.ttf');
}

@font-face {
    font-family: 'Tapestry';
    src: url('./assets/fonts/Tapestry.ttf');
}

@font-face {
    font-family: 'Taprom';
    src: url('./assets/fonts/Taprom.ttf');
}

@font-face {
    font-family: 'Tauri';
    src: url('./assets/fonts/Tauri.ttf');
}

@font-face {
    font-family: 'Taviraj';
    src: url('./assets/fonts/Taviraj.ttf');
}

@font-face {
    font-family: 'Teko';
    src: url('./assets/fonts/Teko.ttf');
}

@font-face {
    font-family: 'Tektur';
    src: url('./assets/fonts/Tektur.ttf');
}

@font-face {
    font-family: 'Telex';
    src: url('./assets/fonts/Telex.ttf');
}

@font-face {
    font-family: 'Tenali Ramakrishna';
    src: url('./assets/fonts/Tenali Ramakrishna.ttf');
}

@font-face {
    font-family: 'Tenor Sans';
    src: url('./assets/fonts/Tenor Sans.ttf');
}

@font-face {
    font-family: 'Text Me One';
    src: url('./assets/fonts/Text Me One.ttf');
}

@font-face {
    font-family: 'Texturina';
    src: url('./assets/fonts/Texturina.ttf');
}

@font-face {
    font-family: 'Thasadith';
    src: url('./assets/fonts/Thasadith.ttf');
}

@font-face {
    font-family: 'The Girl Next Door';
    src: url('./assets/fonts/The Girl Next Door.ttf');
}

@font-face {
    font-family: 'The Nautigal';
    src: url('./assets/fonts/The Nautigal.ttf');
}

@font-face {
    font-family: 'Tienne';
    src: url('./assets/fonts/Tienne.ttf');
}

@font-face {
    font-family: 'Tillana';
    src: url('./assets/fonts/Tillana.ttf');
}

@font-face {
    font-family: 'Tilt Neon';
    src: url('./assets/fonts/Tilt Neon.ttf');
}

@font-face {
    font-family: 'Tilt Prism';
    src: url('./assets/fonts/Tilt Prism.ttf');
}

@font-face {
    font-family: 'Tilt Warp';
    src: url('./assets/fonts/Tilt Warp.ttf');
}

@font-face {
    font-family: 'Timmana';
    src: url('./assets/fonts/Timmana.ttf');
}

@font-face {
    font-family: 'Tinos';
    src: url('./assets/fonts/Tinos.ttf');
}

@font-face {
    font-family: 'Tiro Bangla';
    src: url('./assets/fonts/Tiro Bangla.ttf');
}

@font-face {
    font-family: 'Tiro Devanagari Hindi';
    src: url('./assets/fonts/Tiro Devanagari Hindi.ttf');
}

@font-face {
    font-family: 'Tiro Devanagari Marathi';
    src: url('./assets/fonts/Tiro Devanagari Marathi.ttf');
}

@font-face {
    font-family: 'Tiro Devanagari Sanskrit';
    src: url('./assets/fonts/Tiro Devanagari Sanskrit.ttf');
}

@font-face {
    font-family: 'Tiro Gurmukhi';
    src: url('./assets/fonts/Tiro Gurmukhi.ttf');
}

@font-face {
    font-family: 'Tiro Kannada';
    src: url('./assets/fonts/Tiro Kannada.ttf');
}

@font-face {
    font-family: 'Tiro Tamil';
    src: url('./assets/fonts/Tiro Tamil.ttf');
}

@font-face {
    font-family: 'Tiro Telugu';
    src: url('./assets/fonts/Tiro Telugu.ttf');
}

@font-face {
    font-family: 'Titan One';
    src: url('./assets/fonts/Titan One.ttf');
}

@font-face {
    font-family: 'Titillium Web';
    src: url('./assets/fonts/Titillium Web.ttf');
}

@font-face {
    font-family: 'Tomorrow';
    src: url('./assets/fonts/Tomorrow.ttf');
}

@font-face {
    font-family: 'Tourney';
    src: url('./assets/fonts/Tourney.ttf');
}

@font-face {
    font-family: 'Trade Winds';
    src: url('./assets/fonts/Trade Winds.ttf');
}

@font-face {
    font-family: 'Train One';
    src: url('./assets/fonts/Train One.ttf');
}

@font-face {
    font-family: 'Trirong';
    src: url('./assets/fonts/Trirong.ttf');
}

@font-face {
    font-family: 'Trispace';
    src: url('./assets/fonts/Trispace.ttf');
}

@font-face {
    font-family: 'Trocchi';
    src: url('./assets/fonts/Trocchi.ttf');
}

@font-face {
    font-family: 'Trochut';
    src: url('./assets/fonts/Trochut.ttf');
}

@font-face {
    font-family: 'Truculenta';
    src: url('./assets/fonts/Truculenta.ttf');
}

@font-face {
    font-family: 'Trykker';
    src: url('./assets/fonts/Trykker.ttf');
}

@font-face {
    font-family: 'Tsukimi Rounded';
    src: url('./assets/fonts/Tsukimi Rounded.ttf');
}

@font-face {
    font-family: 'Tulpen One';
    src: url('./assets/fonts/Tulpen One.ttf');
}

@font-face {
    font-family: 'Turret Road';
    src: url('./assets/fonts/Turret Road.ttf');
}

@font-face {
    font-family: 'Twinkle Star';
    src: url('./assets/fonts/Twinkle Star.ttf');
}

@font-face {
    font-family: 'Ubuntu Condensed';
    src: url('./assets/fonts/Ubuntu Condensed.ttf');
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: url('./assets/fonts/Ubuntu Mono.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/fonts/Ubuntu.ttf');
}

@font-face {
    font-family: 'Uchen';
    src: url('./assets/fonts/Uchen.ttf');
}

@font-face {
    font-family: 'Ultra';
    src: url('./assets/fonts/Ultra.ttf');
}

@font-face {
    font-family: 'Unbounded';
    src: url('./assets/fonts/Unbounded.ttf');
}

@font-face {
    font-family: 'Uncial Antiqua';
    src: url('./assets/fonts/Uncial Antiqua.ttf');
}

@font-face {
    font-family: 'Underdog';
    src: url('./assets/fonts/Underdog.ttf');
}

@font-face {
    font-family: 'Unica One';
    src: url('./assets/fonts/Unica One.ttf');
}

@font-face {
    font-family: 'UnifrakturCook';
    src: url('./assets/fonts/UnifrakturCook.ttf');
}

@font-face {
    font-family: 'UnifrakturMaguntia';
    src: url('./assets/fonts/UnifrakturMaguntia.ttf');
}

@font-face {
    font-family: 'Unkempt';
    src: url('./assets/fonts/Unkempt.ttf');
}

@font-face {
    font-family: 'Unlock';
    src: url('./assets/fonts/Unlock.ttf');
}

@font-face {
    font-family: 'Unna';
    src: url('./assets/fonts/Unna.ttf');
}

@font-face {
    font-family: 'Updock';
    src: url('./assets/fonts/Updock.ttf');
}

@font-face {
    font-family: 'Urbanist';
    src: url('./assets/fonts/Urbanist.ttf');
}

@font-face {
    font-family: 'Vampiro One';
    src: url('./assets/fonts/Vampiro One.ttf');
}

@font-face {
    font-family: 'Varela Round';
    src: url('./assets/fonts/Varela Round.ttf');
}

@font-face {
    font-family: 'Varela';
    src: url('./assets/fonts/Varela.ttf');
}

@font-face {
    font-family: 'Varta';
    src: url('./assets/fonts/Varta.ttf');
}

@font-face {
    font-family: 'Vast Shadow';
    src: url('./assets/fonts/Vast Shadow.ttf');
}

@font-face {
    font-family: 'Vazirmatn';
    src: url('./assets/fonts/Vazirmatn.ttf');
}

@font-face {
    font-family: 'Vesper Libre';
    src: url('./assets/fonts/Vesper Libre.ttf');
}

@font-face {
    font-family: 'Viaoda Libre';
    src: url('./assets/fonts/Viaoda Libre.ttf');
}

@font-face {
    font-family: 'Vibes';
    src: url('./assets/fonts/Vibes.ttf');
}

@font-face {
    font-family: 'Vibur';
    src: url('./assets/fonts/Vibur.ttf');
}

@font-face {
    font-family: 'Victor Mono';
    src: url('./assets/fonts/Victor Mono.ttf');
}

@font-face {
    font-family: 'Vidaloka';
    src: url('./assets/fonts/Vidaloka.ttf');
}

@font-face {
    font-family: 'Viga';
    src: url('./assets/fonts/Viga.ttf');
}

@font-face {
    font-family: 'Vina Sans';
    src: url('./assets/fonts/Vina Sans.ttf');
}

@font-face {
    font-family: 'Voces';
    src: url('./assets/fonts/Voces.ttf');
}

@font-face {
    font-family: 'Volkhov';
    src: url('./assets/fonts/Volkhov.ttf');
}

@font-face {
    font-family: 'Vollkorn SC';
    src: url('./assets/fonts/Vollkorn SC.ttf');
}

@font-face {
    font-family: 'Vollkorn';
    src: url('./assets/fonts/Vollkorn.ttf');
}

@font-face {
    font-family: 'Voltaire';
    src: url('./assets/fonts/Voltaire.ttf');
}

@font-face {
    font-family: 'VT323';
    src: url('./assets/fonts/VT323.ttf');
}

@font-face {
    font-family: 'Vujahday Script';
    src: url('./assets/fonts/Vujahday Script.ttf');
}

@font-face {
    font-family: 'Waiting for the Sunrise';
    src: url('./assets/fonts/Waiting for the Sunrise.ttf');
}

@font-face {
    font-family: 'Wallpoet';
    src: url('./assets/fonts/Wallpoet.ttf');
}

@font-face {
    font-family: 'Walter Turncoat';
    src: url('./assets/fonts/Walter Turncoat.ttf');
}

@font-face {
    font-family: 'Warnes';
    src: url('./assets/fonts/Warnes.ttf');
}

@font-face {
    font-family: 'Water Brush';
    src: url('./assets/fonts/Water Brush.ttf');
}

@font-face {
    font-family: 'Waterfall';
    src: url('./assets/fonts/Waterfall.ttf');
}

@font-face {
    font-family: 'Wavefont';
    src: url('./assets/fonts/Wavefont.ttf');
}

@font-face {
    font-family: 'Wellfleet';
    src: url('./assets/fonts/Wellfleet.ttf');
}

@font-face {
    font-family: 'Wendy One';
    src: url('./assets/fonts/Wendy One.ttf');
}

@font-face {
    font-family: 'Whisper';
    src: url('./assets/fonts/Whisper.ttf');
}

@font-face {
    font-family: 'WindSong';
    src: url('./assets/fonts/WindSong.ttf');
}

@font-face {
    font-family: 'Wire One';
    src: url('./assets/fonts/Wire One.ttf');
}

@font-face {
    font-family: 'Wix Madefor Display';
    src: url('./assets/fonts/Wix Madefor Display.ttf');
}

@font-face {
    font-family: 'Wix Madefor Text';
    src: url('./assets/fonts/Wix Madefor Text.ttf');
}

@font-face {
    font-family: 'Work Sans';
    src: url('./assets/fonts/Work Sans.ttf');
}

@font-face {
    font-family: 'Workbench';
    src: url('./assets/fonts/Workbench.ttf');
}

@font-face {
    font-family: 'Xanh Mono';
    src: url('./assets/fonts/Xanh Mono.ttf');
}

@font-face {
    font-family: 'Yaldevi';
    src: url('./assets/fonts/Yaldevi.ttf');
}

@font-face {
    font-family: 'Yanone Kaffeesatz';
    src: url('./assets/fonts/Yanone Kaffeesatz.ttf');
}

@font-face {
    font-family: 'Yantramanav';
    src: url('./assets/fonts/Yantramanav.ttf');
}

@font-face {
    font-family: 'Yarndings 12 Charted';
    src: url('./assets/fonts/Yarndings 12 Charted.ttf');
}

@font-face {
    font-family: 'Yarndings 12';
    src: url('./assets/fonts/Yarndings 12.ttf');
}

@font-face {
    font-family: 'Yarndings 20 Charted';
    src: url('./assets/fonts/Yarndings 20 Charted.ttf');
}

@font-face {
    font-family: 'Yarndings 20';
    src: url('./assets/fonts/Yarndings 20.ttf');
}

@font-face {
    font-family: 'Yatra One';
    src: url('./assets/fonts/Yatra One.ttf');
}

@font-face {
    font-family: 'Yellowtail';
    src: url('./assets/fonts/Yellowtail.ttf');
}

@font-face {
    font-family: 'Yeon Sung';
    src: url('./assets/fonts/Yeon Sung.ttf');
}

@font-face {
    font-family: 'Yeseva One';
    src: url('./assets/fonts/Yeseva One.ttf');
}

@font-face {
    font-family: 'Yesteryear';
    src: url('./assets/fonts/Yesteryear.ttf');
}

@font-face {
    font-family: 'Yomogi';
    src: url('./assets/fonts/Yomogi.ttf');
}

@font-face {
    font-family: 'Young Serif';
    src: url('./assets/fonts/Young Serif.ttf');
}

@font-face {
    font-family: 'Yrsa';
    src: url('./assets/fonts/Yrsa.ttf');
}

@font-face {
    font-family: 'Ysabeau Infant';
    src: url('./assets/fonts/Ysabeau Infant.ttf');
}

@font-face {
    font-family: 'Ysabeau Office';
    src: url('./assets/fonts/Ysabeau Office.ttf');
}

@font-face {
    font-family: 'Ysabeau SC';
    src: url('./assets/fonts/Ysabeau SC.ttf');
}

@font-face {
    font-family: 'Ysabeau';
    src: url('./assets/fonts/Ysabeau.ttf');
}

@font-face {
    font-family: 'Yuji Boku';
    src: url('./assets/fonts/Yuji Boku.ttf');
}

@font-face {
    font-family: 'Yuji Hentaigana Akari';
    src: url('./assets/fonts/Yuji Hentaigana Akari.ttf');
}

@font-face {
    font-family: 'Yuji Hentaigana Akebono';
    src: url('./assets/fonts/Yuji Hentaigana Akebono.ttf');
}

@font-face {
    font-family: 'Yuji Mai';
    src: url('./assets/fonts/Yuji Mai.ttf');
}

@font-face {
    font-family: 'Yuji Syuku';
    src: url('./assets/fonts/Yuji Syuku.ttf');
}

@font-face {
    font-family: 'Yusei Magic';
    src: url('./assets/fonts/Yusei Magic.ttf');
}

@font-face {
    font-family: 'ZCOOL KuaiLe';
    src: url('./assets/fonts/ZCOOL KuaiLe.ttf');
}

@font-face {
    font-family: 'ZCOOL QingKe HuangYou';
    src: url('./assets/fonts/ZCOOL QingKe HuangYou.ttf');
}

@font-face {
    font-family: 'ZCOOL XiaoWei';
    src: url('./assets/fonts/ZCOOL XiaoWei.ttf');
}

@font-face {
    font-family: 'Zen Antique Soft';
    src: url('./assets/fonts/Zen Antique Soft.ttf');
}

@font-face {
    font-family: 'Zen Antique';
    src: url('./assets/fonts/Zen Antique.ttf');
}

@font-face {
    font-family: 'Zen Dots';
    src: url('./assets/fonts/Zen Dots.ttf');
}

@font-face {
    font-family: 'Zen Kaku Gothic Antique';
    src: url('./assets/fonts/Zen Kaku Gothic Antique.ttf');
}

@font-face {
    font-family: 'Zen Kaku Gothic New';
    src: url('./assets/fonts/Zen Kaku Gothic New.ttf');
}

@font-face {
    font-family: 'Zen Kurenaido';
    src: url('./assets/fonts/Zen Kurenaido.ttf');
}

@font-face {
    font-family: 'Zen Loop';
    src: url('./assets/fonts/Zen Loop.ttf');
}

@font-face {
    font-family: 'Zen Maru Gothic';
    src: url('./assets/fonts/Zen Maru Gothic.ttf');
}

@font-face {
    font-family: 'Zen Old Mincho';
    src: url('./assets/fonts/Zen Old Mincho.ttf');
}

@font-face {
    font-family: 'Zen Tokyo Zoo';
    src: url('./assets/fonts/Zen Tokyo Zoo.ttf');
}

@font-face {
    font-family: 'Zeyada';
    src: url('./assets/fonts/Zeyada.ttf');
}

@font-face {
    font-family: 'Zhi Mang Xing';
    src: url('./assets/fonts/Zhi Mang Xing.ttf');
}

@font-face {
    font-family: 'Zilla Slab Highlight';
    src: url('./assets/fonts/Zilla Slab Highlight.ttf');
}

@font-face {
    font-family: 'Zilla Slab';
    src: url('./assets/fonts/Zilla Slab.ttf');
}